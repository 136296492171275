import React, { useContext, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { providerAdminSpecialists } from 'routes/AppUrls';
import leftArrow from 'assets/images/Dashboard/arrow-left.png';
import ReusableButton from 'components/common/Button';
import PhotoImage from 'assets/images/provider/image4.png';

const ViewFormDetails = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Specialists" />,
      Link: providerAdminSpecialists(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Specialist’s Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between p-0">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={leftArrow}
                alt="icon"
                style={{ height: '32px', width: '32px' }}
                className="cursor-pointer"
                onClick={() => navigate(providerAdminSpecialists(locale))}
              />
              <h2 className="fc-292D32 fs-24 fw-400 ff-Akkurat_Bold m-0">
                <FormattedMessage id="Form Details" />
              </h2>
            </Stack>

            <Stack direction="horizontal" gap={3} className="items-center">
              <ReusableButton
                fullWidth={false}
                variant="outlined"
                color="#292D32"
                fontSize="16px"
                fontWeight="400"
                padding="12px 50px"
                height="44px"
                bgColor="transparent"
                fontFamily="Akkurat"
                onClick={() => navigate(providerAdminSpecialists(locale))}
              >
                <FormattedMessage id="Reject" />
              </ReusableButton>

              <ReusableButton
                fullWidth={false}
                variant="contained"
                color="#F5F5F5"
                fontSize="16px"
                fontWeight="400"
                padding="12px 50px"
                height="44px"
                bgColor="#292D32"
                fontFamily="Akkurat"
              >
                <FormattedMessage id="Accept" />
              </ReusableButton>
            </Stack>
          </div>

          <Col md={12} className="mt-3 m-0 p-0">
            <h5 className="fc-292D32 fs-18 fw-400 ff-Akkurat_Bold m-0">
              <FormattedMessage id="Business Details" />
            </h5>
          </Col>

          <Col md={12} className="mt-2 border-1 bColor-DDDDDD p-16">
            <Row className="m-0 p-0">
              <Col md={6} className="">
                <div className="flex justify-between">
                  <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Business Type" />
                  </p>
                  <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Specialist" />
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Name" />
                  </p>
                  <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Joe" />
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Email" />
                  </p>
                  <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="asdfgh@123mail.com" />
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Alternate Email" />
                  </p>
                  <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="asdfgh@123mail.com" />
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Alternate Contact Number" />
                  </p>
                  <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="7896541236" />
                  </p>
                </div>
              </Col>
              <Col md={6} className="">
                <h5 className="fc-1A1A1A fs-18 fw-400 ff-Akkurat_Bold m-0 p-0">
                  <FormattedMessage id="Location Details" />
                </h5>
                <div className="flex justify-between mt-3">
                  <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Country" />
                  </p>
                  <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Lorem Ipsum" />
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="City" />
                  </p>
                  <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Lorem Ipsum" />
                  </p>
                </div>
                <div className="flex justify-between mt-3">
                  <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Address Line" />
                  </p>
                  <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                    <FormattedMessage id="Jumeirah mall, West entrance, 3rd floor, Street 12, 222333" />
                  </p>
                </div>
              </Col>

              <Col md={12} className="mt-4 pt-3">
                <p className="fc-787878 fs-16 fw-400 ff-Akkurat m-0 p-0">
                  <FormattedMessage id="About/Information" />
                </p>

                <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0 mt-2">
                  <FormattedMessage
                    id="Lorem ipsum dolor sit amet consectetur. Integer nunc auctor sit pretium augue malesuada viverra. Enim vel orci eget consectetur dolor tincidunt. Pellentesque et massa gravida elit in at egestas condimentum. 
                                    In feugiat mauris sed laoreet odio duis  nunc auctor sit pretium augue malesuada vi pretium volutpat."
                  />
                </p>
              </Col>
            </Row>
          </Col>

          <Col md={12} className="mt-4 m-0 p-0">
            <h5 className="fc-292D32 fs-18 fw-400 ff-Akkurat_Bold m-0">
              <FormattedMessage id="Bank Details" />
            </h5>
          </Col>

          <Col md={6} className=" mt-2 border-1 bColor-DDDDDD p-16">
            <div className="flex justify-between">
              <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                <FormattedMessage id="Tax ID" />
              </p>
              <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                <FormattedMessage id="RJIO8745965" />
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                <FormattedMessage id="Account Number" />
              </p>
              <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                <FormattedMessage id="1234567890" />
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                <FormattedMessage id="Account Holder Name" />
              </p>
              <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                <FormattedMessage id="Joe" />
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="fc-9A9A9A fs-16 fw-400 ff-Akkurat m-0 p-0">
                <FormattedMessage id="IFSC Code" />
              </p>
              <p className="fc-1A1A1A fs-16 fw-400 ff-Akkurat m-0 p-0">
                <FormattedMessage id="987456" />
              </p>
            </div>
          </Col>

          <Col md={12} className="mt-4 m-0 p-0">
            <h5 className="fc-292D32 fs-18 fw-400 ff-Akkurat_Bold m-0">
              <FormattedMessage id="Images" />
            </h5>

            <div className="mt-2 flex flex-wrap gap-2">
              {[1, 2, 3, 4, 5].map((image, index) => (
                <img
                  src={PhotoImage}
                  alt={`Image`}
                  style={{ height: '124px', width: '240px' }}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewFormDetails;
