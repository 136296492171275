import {
  ADD_CATEGORY,
  ADD_CATEGORY_FAILURE,
  ADD_CATEGORY_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const addCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return { ...state, loading: true };
    case ADD_CATEGORY_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case ADD_CATEGORY_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default addCategoryReducer;
