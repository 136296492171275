import React, { useContext, useState } from 'react';
import { Row, Col, Card, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import 'Pages/SuperAdmin/Dashboard/dashboard.scss';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import Customer from 'assets/images/sidebar/Customer.png';
import Appointment from 'assets/images/sidebar/AppointmentColor.png';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import Avtar from 'assets/images/Dashboard/avtar.png';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomPopover from 'components/common/CustomPopover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Specialist from 'assets/images/Dashboard/Specialist.png';
import { getUserRole } from 'utils/general';

const ProviderAdminDashboard = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const userRole = getUserRole();
  // const [viewData, setViewData] = useState('');

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setViewData(item);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const PopoverData = [
    {
      label: <FormattedMessage id="Delete" />,
      icon: (
        <DeleteOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />
      ),
      // onClick: () => navigate(superAdminEditSubCategory(locale)),
    },
  ];

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Dashboard" />
        </h2>

        <Row className="mt-3 ">
          {userRole === 'company-admin' && (
            <Col lg={2} md={6} sm={12}>
              <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
                <Card.Body>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Provider}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                      <FormattedMessage id="Total Branches" />
                    </p>
                  </Stack>
                  <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                    {'43'}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          )}
          {userRole !== 'specialist-admin' && (
            <Col lg={2} md={6} sm={12}>
              <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
                <Card.Body>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Specialist}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                      <FormattedMessage id="Total Specialists" />
                    </p>
                  </Stack>
                  <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                    {'92'}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Customer}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                    <FormattedMessage id="Total Customers" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {'107'}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Appointment}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                    <FormattedMessage id="Total Appointments" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {'133'}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4 pt-1">
          <div className="d-flex justify-content-between align-items-center ">
            <h5 className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Appointments Today" />
            </h5>
            <Stack
              direction="horizontal"
              gap={2}
              className="items-center underline underline-offset-4"
            >
              <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                <FormattedMessage id="View All" />
              </p>
              <img
                src={rightArrow}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
            </Stack>
          </div>
        </Row>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={`${userRole === 'specialist-admin' ? 3 : 2}`} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Customer" />
            </p>
          </Col>
          {userRole === 'salon-admin' && (
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Specialist" />
              </p>
            </Col>
          )}
          {userRole === 'company-admin' && (
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Provider" />
              </p>
            </Col>
          )}
          <Col lg={`${userRole === 'specialist-admin' ? 3 : 2}`} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Service Location" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Booking ID" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Date & Time" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {[1, 2, 3, 4, 5].map((key, index) => (
            <Row className="m-0 p-0 flex items-center listAppointment">
              <Col
                lg={`${userRole === 'specialist-admin' ? 3 : 2}`}
                md={6}
                sm={12}
              >
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Avtar}
                    alt="icon"
                    style={{ height: '40px', width: '40px' }}
                  />
                  <div>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </div>
                </Stack>
              </Col>

              {(userRole === 'salon-admin' || userRole === 'company-admin') && (
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Avtar}
                      alt="icon"
                      style={{ height: '40px', width: '40px' }}
                    />
                    <div>
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {'John Adams'}
                      </p>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {'00001'}
                      </p>
                    </div>
                  </Stack>
                </Col>
              )}

              <Col
                lg={`${userRole === 'specialist-admin' ? 3 : 2}`}
                md={6}
                sm={12}
              >
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Provider}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {'At Home'}
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                  {'203A Spanish Tn Rd, Kingston'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                  {'1232654551'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                  {'09:30AM'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <div>
                  <MoreVertIcon
                    aria-describedby={id}
                    style={{ fill: '#3C1053', cursor: 'pointer' }}
                    onClick={(e) => handlePopoverClick(e)}
                  />
                  <CustomPopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handlePopoverClose}
                    options={PopoverData}
                  />
                </div>
              </Col>
            </Row>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default ProviderAdminDashboard;
