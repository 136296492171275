import React, { useContext, useState } from 'react';
import { Row, Col, Card, Table, Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import 'Pages/SuperAdmin/Dashboard/dashboard.scss';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import Customer from 'assets/images/sidebar/Customer.png';
import Appointment from 'assets/images/sidebar/AppointmentColor.png';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import Avtar from 'assets/images/Dashboard/avtar.png';
import mail from 'assets/images/Dashboard/mail.png';
import call from 'assets/images/Dashboard/call.png';
import trash from 'assets/images/Dashboard/trash.png';
import Specialist from 'assets/images/Dashboard/Specialist.png';
import ReusableTabs from 'components/common/Tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import SwitchInput from 'components/common/Input/SwitchInput';

const Dashboard = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [tab, setTab] = useState(1);
  const [checked, setChecked] = useState(false);

  const tabData = [
    { label: intl.formatMessage({ id: 'Customers' }) },
    { label: intl.formatMessage({ id: 'Specialists' }) },
    { label: intl.formatMessage({ id: 'Salons' }) },
    { label: intl.formatMessage({ id: 'Companies' }) },
  ];

  const handleTabChange = (val) => {
    setTab(val);
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Dashboard" />
        </h2>

        <Row className="mt-3 ">
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Provider}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                    <FormattedMessage id="Total Salons" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {'43'}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Specialist}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                    <FormattedMessage id="Total Specialists" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {'92'}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Customer}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                    <FormattedMessage id="Total Customers" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {'107'}
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Appointment}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                    <FormattedMessage id="Total Appointments" />
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-28 fc-292D32 fw-400 ff-Akkurat_Bold mt-2">
                  {'133'}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4 pt-1">
          <div className="d-flex justify-content-between align-items-center ">
            <h5 className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Appointments Today" />
            </h5>
            <Stack
              direction="horizontal"
              gap={2}
              className="items-center underline underline-offset-4"
            >
              <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                <FormattedMessage id="View All" />
              </p>
              <img
                src={rightArrow}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
            </Stack>
          </div>
        </Row>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Customer" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Provider" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Service Location" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Booking ID" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Date & Time" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {[1, 2, 3, 4, 5].map((key, index) => (
            <Row className="m-0 p-0 flex items-center listAppointment">
              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Avtar}
                    alt="icon"
                    style={{ height: '40px', width: '40px' }}
                  />
                  <div>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </div>
                </Stack>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Avtar}
                    alt="icon"
                    style={{ height: '40px', width: '40px' }}
                  />
                  <div>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Specialist Name'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </div>
                </Stack>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Provider}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {'At Home'}
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                  {'203A Spanish Tn Rd, Kingston'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                  {'1232654551'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                  {'09:30AM'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={2} className="items-center ">
                  <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                    <FormattedMessage id="View Details" />
                  </p>
                  <img
                    src={rightArrow}
                    alt="icon"
                    style={{ height: '20px', width: '20px' }}
                  />
                </Stack>
              </Col>
            </Row>
          ))}
        </Row>

        <Row className="m-0 p-0 mt-5">
          <h2 className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
            <FormattedMessage id="New Users" />
          </h2>
          <Col md={12} lg={12} xl={12} xxl={8} className="m-0 p-0 mt-3">
            <ReusableTabs
              tabs={tabData}
              defaultTab={1}
              currentTab={(val) => handleTabChange(val)}
            />
          </Col>
        </Row>

        {tab === 1 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Specialist" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Location" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Status" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <SwitchInput
                      label={true}
                      checked={checked}
                      onChange={(e) => handleSwitchChange(e)}
                      color="secondary"
                    />
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}

        {tab === 2 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Specialist" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Location" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Status" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <SwitchInput
                      label={true}
                      checked={checked}
                      onChange={(e) => handleSwitchChange(e)}
                      color="secondary"
                    />
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}

        {tab === 3 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Specialist" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Location" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Status" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <SwitchInput
                      label={true}
                      checked={checked}
                      onChange={(e) => handleSwitchChange(e)}
                      color="secondary"
                    />
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}

        {tab === 4 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Specialist" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Location" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Status" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <SwitchInput
                      label={true}
                      checked={checked}
                      onChange={(e) => handleSwitchChange(e)}
                      color="secondary"
                    />
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default Dashboard;
