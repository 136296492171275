import {
  UPDATE_SPECIALIST_STATUS_PROVIDER,
  UPDATE_SPECIALIST_STATUS_PROVIDER_FAILURE,
  UPDATE_SPECIALIST_STATUS_PROVIDER_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const updateSpecialistStatusProviderReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_SPECIALIST_STATUS_PROVIDER:
      return { ...state, loading: true };
    case UPDATE_SPECIALIST_STATUS_PROVIDER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case UPDATE_SPECIALIST_STATUS_PROVIDER_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default updateSpecialistStatusProviderReducer;
