import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import SelectInput from 'components/common/Input/SelectInput';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import crossIcon from 'assets/images/provider/crossIcon.png';
import ReusableButton from 'components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { countryCityDropdownCallAPI } from 'store/CountryCityList/action';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { superAdminProvider } from 'routes/AppUrls';
import CheckValidation from 'utils/Validation';
import { updateAllProviderListDetailsCallAPI } from 'store/SuperAdmin/updateAllProviderListDetails/action';
import ToastNotification from 'components/common/Toast';
import { imageUploadCallAPI } from 'store/ImageUpload/action';

const SpecialistProvider = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const salonBrandImageFileInputRef = useRef();
  const salonImageRef = useRef();
  const [salonBrandImageFile, setSalonBrandImageFile] = useState(
    data?.profile_photo?.file_url ? data?.profile_photo?.file_url : null
  );
  const [salonImages, setSalonImages] = useState(
    data?.bussiness_images?.length > 0
      ? data?.bussiness_images.map((k) => {
          return { image: k?.image, id: k?.id };
        })
      : []
  );

  const phoneNumberInputRef = useRef(null);
  const alternatePhoneNumberInputRef = useRef(null);
  const [isError, setIsError] = useState(null);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isAlternatePhoneNumberValid, setIsAlternatePhoneNumberValid] =
    useState(true);

  const countryCityData = useSelector(
    (state) => state?.countryCityDropdownReducer?.data?.data
  );

  const [formValue, setFormValue] = useState({
    ownerName: data?.name?.name_en ? data?.name?.name_en : '',
    addressArea: data?.address ? data?.address : '',
    country: data?.country?.name ? data?.country?.name : '',
    city: data?.city?.name ? data?.city?.name : '',
    phoneNumber: data?.contact_number ? data?.contact_number : '',
    countryCode: '+974',
    alternatePhoneNumber: data?.alternate_contact_number
      ? data?.alternate_contact_number
      : '',
    brandImage: data?.profile_photo?.id ? data?.profile_photo?.id : '',
    countryCodeAlternateNumber: '+974',
    email: data?.email ? data?.email : '',
    alternateEmail: data?.alternate_email ? data?.alternate_email : '',
    about: data?.about_information_en ? data?.about_information_en : '',
    bussinessImages:
      data?.bussiness_images?.length > 0
        ? data?.bussiness_images.map((k) => k?.id)
        : [],
  });

  const [formId, setFormId] = useState({
    country: data?.country?.id ? data?.country?.id : '',
    city: data?.city?.id ? data?.city?.id : '',
  });

  useEffect(() => {
    getCountryCityList();
  }, []);

  const getCountryCityList = () => {
    dispatch(countryCityDropdownCallAPI({}, { naviagte: navigate }));
  };

  const handleCountryCodeChange = (value, name) => {
    if (name === 'phoneNumber') {
      setFormValue({ ...formValue, countryCode: `+${value}` });
    } else if (name === 'alternatePhoneNumber') {
      setFormValue({ ...formValue, countryCodeAlternateNumber: `+${value}` });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumberValue = e.target.value;
    setFormValue((prev) => ({
      ...prev,
      [e.target.name]: phoneNumberValue,
    }));

    if (e.target.name === 'phoneNumber') {
      const fullPhoneNumber = `${formValue?.countryCode}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsPhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsPhoneNumberValid(false);
      }
    } else if (e.target.name === 'alternatePhoneNumber') {
      const fullPhoneNumber = `${formValue?.countryCodeAlternateNumber}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsAlternatePhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsAlternatePhoneNumberValid(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value?.name });
    setFormId({ ...formId, [name]: value?.id });
  };

  const handleSalonBrandImageFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSuccess(res),
        })
      );
      setSalonBrandImageFile(URL.createObjectURL(file));
    }
  };

  const handleSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setFormValue({ ...formValue, ['brandImage']: res?.data?.data?.[0]?.id });
  };

  const handleSalonImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSalonImagesSuccess(res, file),
        })
      );
    }
  };

  const handleSalonImagesSuccess = (res, file) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setSalonImages([
      ...salonImages,
      { image: URL.createObjectURL(file), id: res?.data?.data?.[0]?.id },
    ]);
    setFormValue({
      ...formValue,
      ['bussinessImages']: [
        ...formValue?.bussinessImages,
        res?.data?.data?.[0]?.id,
      ],
    });
  };

  const handleSalonBrandImageUpload = () => {
    salonBrandImageFileInputRef.current.click();
  };

  const handleSalonImageUpload = () => {
    salonImageRef.current.click();
  };

  const handleDeleteSalonImage = (data, index) => {
    const filterImages = salonImages?.filter((value) => value !== data);
    setSalonImages(filterImages);
    setFormValue({
      ...formValue,
      ['bussinessImages']: filterImages.map((k) => k?.id),
    });
  };

  const handleUpdateProvider = () => {
    const { ownerName, country, city, phoneNumber, email } = formValue;
    const error = CheckValidation({
      ownerName,
      country,
      city,
      phoneNumber,
      email,
    });

    setIsError(error);
    if (error.submit) {
      let body = {
        name_en: formValue?.ownerName,
        name_ar: formValue?.ownerName,
        searched_business_name_en: formValue?.ownerName,
        searched_business_name_ar: formValue?.ownerName,
        address: formValue?.addressArea,
        country: formId?.country,
        city: formId?.city,
        contact_number: formValue?.phoneNumber,
        is_contact_verified: false,
        alternate_contact_number: formValue?.alternatePhoneNumber,
        is_alternate_contact_verified: false,
        email: formValue?.email,
        is_email_verified: false,
        alternate_email: formValue?.alternateEmail,
        is_alternate_email_verified: false,
        about_information_en: formValue?.about,
        about_information_ar: formValue?.about,
        profile_photo: formValue?.brandImage,
        bussiness_images: formValue?.bussinessImages,
      };
      dispatch(
        updateAllProviderListDetailsCallAPI(body, {
          id: data?.id,
          run: true,
          handleFunction: (res) => handleRedirect(res),
          navigate: navigate,
        })
      );
    }
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    navigate(superAdminProvider(locale));
  };

  return (
    <>
      <Row className="m-0 p-0">
        <Col xs={12} md={6}>
          <LabelWithInput
            label={<FormattedMessage id="Specialist’s Name" />}
            placeholder={intl.formatMessage({ id: 'Specialist’s Name' })}
            name="ownerName"
            type="text"
            value={formValue?.ownerName}
            onChange={handleChange}
            errorText={
              isError &&
              isError.ownerName.error &&
              isError.ownerName.errorMessage
            }
          />
        </Col>

        <Col xs={12} md={6}></Col>

        <Col xs={12} md={6} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="Address of the serving area" />}
            placeholder={intl.formatMessage({
              id: 'Address of the serving area',
            })}
            name="addressArea"
            type="text"
            value={formValue?.addressArea}
            onChange={handleChange}
          />
        </Col>

        <Col xs={12} md={3} className="mt-3">
          <SelectInput
            label={<FormattedMessage id="Country" />}
            placeholder={intl.formatMessage({ id: 'Country' })}
            name={'country'}
            value={formValue?.country}
            data={countryCityData}
            onChange={(e) => handleInputChange(e)}
            errorText={
              isError && isError.country.error && isError.country.errorMessage
            }
          />
        </Col>

        <Col xs={12} md={3} className="mt-3">
          <SelectInput
            label={<FormattedMessage id="City" />}
            placeholder={intl.formatMessage({ id: 'City' })}
            name={'city'}
            value={formValue?.city}
            data={
              countryCityData?.find(
                (value) => value?.name === formValue?.country
              )?.city || []
            }
            onChange={(e) => handleInputChange(e)}
            errorText={
              isError && isError.country.error && isError.country.errorMessage
            }
          />
        </Col>

        <Col xs={12} md={6} className="mt-3">
          <PhoneNumberInput
            label={<FormattedMessage id="Contact Number" />}
            country={'qa'}
            value={formValue?.countryCode}
            name={'phoneNumber'}
            onCountryCodeChange={(e) =>
              handleCountryCodeChange(e, 'phoneNumber')
            }
            onPhoneNumberChange={handlePhoneNumberChange}
            phoneNumber={formValue.phoneNumber}
            phoneNumberRef={phoneNumberInputRef}
            isPhoneNumberValid={isPhoneNumberValid}
            isError={
              isError &&
              isError.phoneNumber.error &&
              isError.phoneNumber.errorMessage
            }
            errorMessage="Invalid phone number for selected country code"
          />
        </Col>

        <Col xs={12} md={6} className="mt-3">
          <PhoneNumberInput
            label={<FormattedMessage id="Alternate Contact Number" />}
            country={'qa'}
            value={formValue?.countryCodeAlternateNumber}
            name={'alternatePhoneNumber'}
            onCountryCodeChange={(e) =>
              handleCountryCodeChange(e, 'alternatePhoneNumber')
            }
            onPhoneNumberChange={handlePhoneNumberChange}
            phoneNumber={formValue.alternatePhoneNumber}
            phoneNumberRef={alternatePhoneNumberInputRef}
            isPhoneNumberValid={isAlternatePhoneNumberValid}
            // isError={isError}
            errorMessage="Invalid phone number for selected country code"
          />
        </Col>

        <Col xs={12} md={6} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="Email ID" />}
            placeholder={intl.formatMessage({ id: 'Email ID' })}
            name="email"
            type="text"
            value={formValue?.email}
            onChange={handleChange}
            errorText={
              isError && isError.email.error && isError.email.errorMessage
            }
          />
        </Col>

        <Col xs={12} md={6} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="Alternate Email ID" />}
            placeholder={intl.formatMessage({
              id: 'Alternate Email ID',
            })}
            name="alternateEmail"
            type="text"
            value={formValue?.alternateEmail}
            onChange={handleChange}
          />
        </Col>

        <Col xs={12} md={12} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="About/Information" />}
            placeholder={intl.formatMessage({
              id: 'About/Information',
            })}
            height="100"
            multiline
            name="about"
            value={formValue?.about}
            onChange={(e) => handleChange(e)}
          />
        </Col>

        <input
          ref={salonBrandImageFileInputRef}
          type="file"
          accept=".png, .jpg, .jpeg"
          disabled={false}
          style={{ display: 'none' }}
          onChange={handleSalonBrandImageFileChange}
        />

        <div className="mt-4">
          <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
            <FormattedMessage id="Photos" />
          </p>
          <Stack direction="horizontal" gap={4} className="items-center mt-4">
            <div className="AvtarImageIcon">
              {salonBrandImageFile && (
                <img
                  src={salonBrandImageFile}
                  alt={'icon'}
                  style={{ height: '88px', width: '88px', borderRadius: '50%' }}
                />
              )}
              <img
                src={ProfileIcon}
                alt="icon"
                style={{ height: '32px', width: '32px' }}
                className="profileIcon"
                onClick={handleSalonBrandImageUpload}
              />
            </div>
            <div>
              <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Upload Brand Image" />
              </p>
              <p className="p-0 m-0 fs-14 fc-9A9A9A fw-400 ff-Akkurat">
                <FormattedMessage id="A picture will help people recognize you" />
              </p>
            </div>
          </Stack>
        </div>

        <Col xs={12} md={12} className="mt-3">
          <div className="flex justify-between items-center">
            <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Images" />
            </p>
            {salonImages?.length > 0 && (
              <ReusableButton
                fullWidth={false}
                variant="outlined"
                color="#292D32"
                fontSize="16px"
                fontWeight="400"
                padding="12px 25px"
                height="44px"
                bgColor="transparent"
                fontFamily="Akkurat"
                onClick={handleSalonImageUpload}
              >
                <img
                  src={AddBlackColor}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                  className="me-2"
                />
                <FormattedMessage id="Add Images" />
              </ReusableButton>
            )}
          </div>

          <input
            ref={salonImageRef}
            type="file"
            accept=".png, .jpg, .jpeg"
            disabled={false}
            style={{ display: 'none' }}
            onChange={handleSalonImageChange}
          />

          {salonImages?.length <= 0 ? (
            <div className="mt-2 bussinessImages flex justify-center align-center">
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center cursor-pointer"
                onClick={handleSalonImageUpload}
              >
                <img
                  src={AddBlackColor}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                />
                <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="AddImageEn" />
                </p>
              </Stack>
            </div>
          ) : (
            <div className="flex flex-wrap gap-2 mt-3">
              {salonImages.map((key, index) => (
                <div className="relative">
                  <img
                    src={key?.image}
                    alt="image"
                    style={{ height: '122px', width: '246px' }}
                  />
                  <img
                    src={crossIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => handleDeleteSalonImage(key, index)}
                  />
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <Row className="mt-4 items-end m-0 p-0">
        <div className="flex justify-end">
          <Stack direction="horizontal" gap={3} className="items-center">
            <ReusableButton
              fullWidth={false}
              variant="outlined"
              color="#292D32"
              fontSize="16px"
              fontWeight="400"
              padding="12px 25px"
              height="44px"
              bgColor="transparent"
              fontFamily="Akkurat"
              onClick={() => navigate(superAdminProvider(locale))}
            >
              <FormattedMessage id="Discard Changes" />
            </ReusableButton>

            <ReusableButton
              fullWidth={false}
              variant="contained"
              color="#F5F5F5"
              fontSize="16px"
              fontWeight="400"
              padding="12px 25px"
              height="44px"
              bgColor="#292D32"
              fontFamily="Akkurat"
              onClick={() => handleUpdateProvider()}
            >
              <FormattedMessage id="Save Changes" />
            </ReusableButton>
          </Stack>
        </div>
      </Row>
    </>
  );
};

export default SpecialistProvider;
