import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import crossIcon from 'assets/images/provider/crossIcon.png';
import ReusableButton from 'components/common/Button';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';
import sidebarLogo from 'assets/images/sidebar/sidebarLogo.png';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import SelectInput from 'components/common/Input/SelectInput';
import { countryCityDropdownCallAPI } from 'store/CountryCityList/action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ToastNotification from 'components/common/Toast';
import { imageUploadCallAPI } from 'store/ImageUpload/action';
import CheckValidation from 'utils/Validation';
import { updateAllProviderListDetailsCallAPI } from 'store/SuperAdmin/updateAllProviderListDetails/action';
import { providerAdminDashboard } from 'routes/AppUrls';

const CompanyOnboarding = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const { locale } = useContext(LocaleContext);
  const [isError, setIsError] = useState(null);

  const profileData = useSelector((state) => state?.userMeReducer?.data?.data);

  const companyBrandImageFileInputRef = useRef();
  const companyImageRef = useRef();
  const [companyBrandImageFile, setCompanyBrandImageFile] = useState(
    profileData?.provider_details?.profile_photo?.file_url
      ? profileData?.provider_details?.profile_photo?.file_url
      : null
  );

  const [companyImages, setCompanyImages] = useState(
    profileData?.provider_details?.bussiness_images?.length > 0
      ? profileData?.provider_details?.bussiness_images.map((k) => {
          return { image: k?.image, id: k?.id };
        })
      : []
  );

  const countryCityData = useSelector(
    (state) => state?.countryCityDropdownReducer?.data?.data
  );

  const [formValue, setFormValue] = useState({
    country: profileData?.provider_details?.country?.name ?? '',
    countryCode: 'qa',
    city: profileData?.provider_details?.city?.name ?? '',
    phoneNumber: profileData?.provider_details?.country?.name ?? '',
    addressArea: profileData?.provider_details?.address ?? '',
    email: profileData?.provider_details?.country?.name ?? '',
    about: profileData?.provider_details?.about_information_en ?? '',
    brandImage: profileData?.provider_details?.profile_photo?.id ?? '',
    bussinessImages:
      profileData?.provider_details?.bussiness_images?.length > 0
        ? profileData?.provider_details?.bussiness_images.map((k) => k?.id)
        : [],
  });

  const [formId, setFormId] = useState({
    country: profileData?.provider_details?.country?.id ?? '',
    city: profileData?.provider_details?.city?.id ?? '',
  });

  useEffect(() => {
    getCountryCityList();
  }, []);

  const getCountryCityList = () => {
    dispatch(countryCityDropdownCallAPI({}, { naviagte: navigate }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value?.name });
    setFormId({ ...formId, [name]: value?.id });
  };

  const handleCompanyBrandImageFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSuccess(res),
        })
      );
      setCompanyBrandImageFile(URL.createObjectURL(file));
    }
  };

  const handleSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setFormValue({ ...formValue, ['brandImage']: res?.data?.data?.[0]?.id });
  };

  const handleCompanyImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSalonImagesSuccess(res, file),
        })
      );
    }
  };

  const handleSalonImagesSuccess = (res, file) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setCompanyImages([
      ...companyImages,
      { image: URL.createObjectURL(file), id: res?.data?.data?.[0]?.id },
    ]);
    setFormValue({
      ...formValue,
      ['bussinessImages']: [
        ...formValue?.bussinessImages,
        res?.data?.data?.[0]?.id,
      ],
    });
  };

  const handleCompanyBrandImageUpload = () => {
    companyBrandImageFileInputRef.current.click();
  };

  const handleCompanyImageUpload = () => {
    companyImageRef.current.click();
  };

  const handleDeleteCompanyImage = (data, index) => {
    const filterImages = companyImages?.filter((value) => value !== data);
    setCompanyImages(filterImages);
    setFormValue({
      ...formValue,
      ['bussinessImages']: filterImages.map((k) => k?.id),
    });
  };

  const handleUpdatePrfile = () => {
    const { country, city } = formValue;
    const error = CheckValidation({
      country,
      city,
    });

    setIsError(error);
    if (error.submit) {
      let body = {
        address: formValue?.addressArea,
        country: formId?.country,
        city: formId?.city,
        about_information_en: formValue?.about,
        about_information_ar: formValue?.about,
        profile_photo: formValue?.brandImage,
        bussiness_images: formValue?.bussinessImages,
        email: profileData?.provider_details?.email,
        searched_business_name_en:
          profileData?.provider_details?.searched_business_name_en ?? 'company',
        searched_business_name_ar:
          profileData?.provider_details?.searched_business_name_ar ?? 'company',
      };
      dispatch(
        updateAllProviderListDetailsCallAPI(body, {
          id: profileData?.provider_details?.id,
          run: true,
          handleFunction: (res) => handleRedirect(res),
          navigate: navigate,
        })
      );
    }
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    navigate(providerAdminDashboard(locale));
  };

  return (
    <>
      <Row className="m-0 p-16">
        <div className="flex justify-between items-center">
          <div>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
              <FormattedMessage id="Step 1/3" />
            </p>
            <h2 className="p-0 m-0 mt-2 fs-32 fc-292D32 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Tell us about your business" />
            </h2>
          </div>

          <div>
            <img src={sidebarLogo} alt={'logo'} />
          </div>
        </div>

        <Row className="m-0 p-0 mt-3">
          <input
            ref={companyBrandImageFileInputRef}
            type="file"
            accept=".png, .jpg, .jpeg"
            disabled={false}
            style={{ display: 'none' }}
            onChange={handleCompanyBrandImageFileChange}
          />

          <div className="mt-4 flex items-center">
            <Stack direction="horizontal" gap={4} className="items-center">
              <div className="AvtarImageIcon">
                {companyBrandImageFile && (
                  <img
                    src={companyBrandImageFile}
                    alt={'icon'}
                    style={{
                      height: '88px',
                      width: '88px',
                      borderRadius: '50%',
                    }}
                  />
                )}
                <img
                  src={ProfileIcon}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="profileIcon"
                  onClick={handleCompanyBrandImageUpload}
                />
              </div>
              <div>
                <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Company Name" />
                </p>
                <p className="p-0 m-0 fs-16 fc-A4AAB2 fw-400 ff-Akkurat">
                  <FormattedMessage id="Owner" /> {' : '}
                  <span className="fc-818488 ff-Akkurat_Bold">
                    {profileData?.provider_details?.name?.name_en}
                  </span>
                </p>
              </div>
            </Stack>
          </div>

          <Col xs={12} md={5} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={callIcon}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                <FormattedMessage id="Phone number" />
              </p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {profileData?.provider_details?.contact_number}
            </p>
          </Col>

          <Col xs={12} md={5} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={mailIcon}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                <FormattedMessage id="Email" />
              </p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {profileData?.provider_details?.email}
            </p>
          </Col>

          <Col xs={12} md={2} className="mt-3 text-center">
            <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
              <FormattedMessage id="Request Date" />
            </p>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {profileData?.provider_details?.requested_on
                ? moment(profileData?.provider_details?.requested_on).format(
                    'DD MMM YYYY'
                  )
                : ''}
            </p>
          </Col>
        </Row>

        <Row className="m-0 p-0 ">
          <Col xs={12} md={12} className="mt-4 pt-2">
            <h2 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Business Details" />
            </h2>
          </Col>

          <Col xs={12} md={6} className="mt-3">
            <LabelWithInput
              label={<FormattedMessage id="Address of the serving area" />}
              placeholder={intl.formatMessage({
                id: 'Address of the serving area',
              })}
              name="addressArea"
              type="text"
              value={formValue?.addressArea}
              onChange={handleChange}
            />
          </Col>

          <Col xs={12} md={3} className="mt-3">
            <SelectInput
              label={<FormattedMessage id="Country" />}
              placeholder={intl.formatMessage({ id: 'Country' })}
              name={'country'}
              value={formValue?.country}
              data={countryCityData}
              onChange={(e) => handleInputChange(e)}
              errorText={
                isError && isError.country.error && isError.country.errorMessage
              }
            />
          </Col>

          <Col xs={12} md={3} className="mt-3">
            <SelectInput
              label={<FormattedMessage id="City" />}
              placeholder={intl.formatMessage({ id: 'City' })}
              name={'city'}
              value={formValue?.city}
              data={
                countryCityData?.find(
                  (value) => value?.name === formValue?.country
                )?.city || []
              }
              onChange={(e) => handleInputChange(e)}
              errorText={
                isError && isError.city.error && isError.city.errorMessage
              }
            />
          </Col>

          <Col xs={12} md={6} className="mt-3">
            <LabelWithInput
              label={<FormattedMessage id="Tax ID" />}
              placeholder={intl.formatMessage({
                id: 'Tax ID',
              })}
              name="taxId"
              type="text"
              value={formValue?.taxId}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Col xs={12} md={12} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="About/Information" />}
            placeholder={intl.formatMessage({
              id: 'About/Information',
            })}
            height="100"
            multiline
            name="about"
            value={formValue?.about}
            onChange={(e) => handleChange(e)}
          />
        </Col>

        <Col xs={12} md={12} className="mt-3">
          <div className="flex justify-between items-center">
            <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Images" />
            </p>
            {companyImages?.length > 0 && (
              <ReusableButton
                fullWidth={false}
                variant="outlined"
                color="#292D32"
                fontSize="16px"
                fontWeight="400"
                padding="12px 25px"
                height="44px"
                bgColor="transparent"
                fontFamily="Akkurat"
                onClick={handleCompanyImageUpload}
              >
                <img
                  src={AddBlackColor}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                  className="me-2"
                />
                <FormattedMessage id="Add Images" />
              </ReusableButton>
            )}
          </div>

          <input
            ref={companyImageRef}
            type="file"
            accept=".png, .jpg, .jpeg"
            disabled={false}
            style={{ display: 'none' }}
            onChange={handleCompanyImageChange}
          />

          {companyImages?.length <= 0 ? (
            <div className="mt-2 bussinessImages flex justify-center align-center">
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center cursor-pointer"
                onClick={handleCompanyImageUpload}
              >
                <img
                  src={AddBlackColor}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                />
                <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="AddImageEn" />
                </p>
              </Stack>
            </div>
          ) : (
            <div className="flex flex-wrap gap-2 mt-3">
              {companyImages.map((key, index) => (
                <div className="relative">
                  <img
                    src={key?.image}
                    alt="image"
                    style={{ height: '122px', width: '246px' }}
                  />
                  <img
                    src={crossIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => handleDeleteCompanyImage(key, index)}
                  />
                </div>
              ))}
            </div>
          )}
        </Col>

        <Row className="mt-4 items-end m-0 p-0">
          <div className="flex justify-end">
            <Stack direction="horizontal" gap={3} className="items-center">
              <ReusableButton
                fullWidth={false}
                variant="outlined"
                color="#292D32"
                fontSize="16px"
                fontWeight="400"
                padding="12px 25px"
                height="44px"
                bgColor="transparent"
                fontFamily="Akkurat"
                onClick={() => navigate(-1)}
              >
                <FormattedMessage id="Cancel" />
              </ReusableButton>

              <ReusableButton
                fullWidth={false}
                variant="contained"
                color="#F5F5F5"
                fontSize="16px"
                fontWeight="400"
                padding="12px 35px"
                height="44px"
                bgColor="#292D32"
                fontFamily="Akkurat"
                onClick={() => handleUpdatePrfile()}
              >
                <FormattedMessage id="Next" />
              </ReusableButton>
            </Stack>
          </div>
        </Row>
      </Row>
    </>
  );
};

export default CompanyOnboarding;
