import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminAppointments } from 'routes/AppUrls';
import ArrowLeftIcon from 'assets/images/arrow-left.png';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import PhotoImage from 'assets/images/provider/image4.png';
import HouseIcon from 'assets/images/provider/HouseLine.png';
import ClockIcon from 'assets/images/provider/clock.png';
import AppointmentColor from 'assets/images/sidebar/AppointmentColor.png';

const AppointmentDetails = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Appointments" />,
      Link: superAdminAppointments(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Appointment Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <Col xs={12} md={12} className="p-0">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={ArrowLeftIcon}
                alt="icon"
                style={{ height: '24px', width: '24px' }}
                className="cursor-pointer"
                onClick={() => navigate(superAdminAppointments(locale))}
              />
              <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Appointment no." /> {'1232654551'}
              </p>
            </Stack>
          </Col>

          <Col xs={12} md={12} className="mt-4 border-b pb-4 p-0">
            <div className="gap-3 flex">
              <img
                src={PhotoImage}
                alt={'icon'}
                style={{
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%',
                }}
              />
              <div className="flex-col d-flex justify-between">
                <div>
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Customer Name" />
                  </p>
                </div>
                <div>
                  <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                    ID:00001
                  </p>
                </div>
                <div className="flex gap-5 flex-wrap">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={callIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      9874456321
                    </p>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={mailIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      random123@mail
                    </p>
                  </Stack>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={12} className="mt-3 border-b pb-4 p-0">
            <h5 className="p-0 m-0 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Appointment Details" />
            </h5>
            <div className="flex gap-5 flex-wrap mt-3 pt-1">
              <Stack direction="horizontal" gap={2} className="items-center">
                <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                  <FormattedMessage id="Service Type" /> {':'}
                </p>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={HouseIcon}
                    alt="icon"
                    style={{ height: '20px', width: '20px' }}
                  />
                  <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                    At Home
                  </p>
                </Stack>
              </Stack>

              <Stack direction="horizontal" gap={3} className="items-center">
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={AppointmentColor}
                    alt="icon"
                    style={{ height: '20px', width: '20px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                    January 10, 2024
                  </p>
                </Stack>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={ClockIcon}
                    alt="icon"
                    style={{ height: '20px', width: '20px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                    09:30AM
                  </p>
                </Stack>
              </Stack>

              <Stack direction="horizontal" gap={2} className="items-center">
                <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                  <FormattedMessage id="Location" /> {':'}
                </p>
                <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                  203A Spanish Tn Rd, Kingston
                </p>
              </Stack>
            </div>
          </Col>

          <Col xs={12} md={12} className="mt-3 border-b pb-4 p-0">
            <h5 className="p-0 m-0 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Provider Details" />
            </h5>
            <div className="gap-5 flex mt-3">
              <div className="gap-3 flex">
                <img
                  src={PhotoImage}
                  alt={'icon'}
                  style={{
                    height: '64px',
                    width: '64px',
                    borderRadius: '50%',
                  }}
                />
                <div className="flex-col d-flex justify-between">
                  <div>
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="Specialist’s Name" />
                    </p>
                  </div>
                  <div>
                    <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                      ID:00001
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-col d-flex justify-between">
                <div>
                  <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                    <FormattedMessage id="Contact Info" />
                  </p>
                </div>
                <div className="flex gap-5">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={callIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      9874456321
                    </p>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={mailIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      random123@mail
                    </p>
                  </Stack>
                </div>
              </div>
            </div>
          </Col>

          <h5 className="p-0 m-0 mt-3 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
            <FormattedMessage id="Services" />
          </h5>

          {[1, 2, 3].map((k, i) => (
            <Col xs={12} md={12} className="mt-3 border-b pb-2 p-0">
              <div className="flex justify-between">
                <div className="gap-3 flex items-center">
                  <img
                    src={PhotoImage}
                    alt={'icon'}
                    style={{
                      height: '92px',
                      width: '92px',
                    }}
                  />
                  <div>
                    <p className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
                      Haircut
                    </p>
                    <p className="p-0 m-0 mt-3 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      Basic cut | Reshape hair length.
                    </p>
                  </div>
                </div>

                <div className="flex items-center">
                  <div>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      4.9 | 50min
                    </p>
                    <p className="p-0 m-0 mt-3 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                      130 SAR
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default AppointmentDetails;
