import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import 'Pages/SuperAdmin/Dashboard/dashboard.scss';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import addIcon from 'assets/images/Dashboard/add.png';
import mail from 'assets/images/Dashboard/mail.png';
import call from 'assets/images/Dashboard/call.png';
import trash from 'assets/images/Dashboard/trash.png';
import { FormattedMessage, useIntl } from 'react-intl';
import ReusableButton from 'components/common/Button';
import {
  providerAdminAddBranches,
  providerAdminBranchesDetails,
} from 'routes/AppUrls';
import SwitchInput from 'components/common/Input/SwitchInput';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyBranchProviderCallAPI } from 'store/ProviderAdmin/getCompanyBranchProvider/action';
import { deleteBranchProviderCallAPI } from 'store/ProviderAdmin/deleteBranchProvider/action';
import ToastNotification from 'components/common/Toast';

const ProviderAdminBranches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [conf, setConf] = ToastNotification();
  const { locale } = useContext(LocaleContext);
  const [checked, setChecked] = useState(false);

  const getBranchListData = useSelector(
    (state) => state?.getCompanyBranchProviderReducer?.data
  );

  const [branchListData, setbranchListData] = useState(
    getBranchListData?.data?.length > 0 ? getBranchListData?.data : []
  );

  useEffect(() => {
    getBranchList();
  }, []);

  const getBranchList = () => {
    dispatch(
      getCompanyBranchProviderCallAPI(
        {},
        {
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setbranchListData(res?.data?.data);
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  const handleBranchDelete = (id) => {
    dispatch(
      deleteBranchProviderCallAPI(
        {},
        {
          id: id,
          run: true,
          handleFunction: (res) => handleDeleteSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleDeleteSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    getBranchList();
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <div className="flex justify-between">
          <h2 className="fc-1A1A1A fs-32 fw-400 ff-Akkurat_Bold m-0">
            <FormattedMessage id="Branches" />
          </h2>

          <ReusableButton
            fullWidth={false}
            variant="contained"
            color="#F5F5F5"
            fontSize="16px"
            fontWeight="400"
            padding="12px"
            height="48px"
            bgColor="#1A1A1A"
            fontFamily="Akkurat"
            onClick={() => navigate(providerAdminAddBranches(locale))}
          >
            <img
              src={addIcon}
              alt="icon"
              style={{ height: '24px', width: '24px' }}
              className="me-2"
            />
            <FormattedMessage id="Add Branch" />
          </ReusableButton>
        </div>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={3} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-1A1A1A ff-Akkurat_Bold">
              <FormattedMessage id="Branch" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-1A1A1A ff-Akkurat_Bold">
              <FormattedMessage id="Branch Manager" />
            </p>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-1A1A1A ff-Akkurat_Bold">
              <FormattedMessage id="Contact Info" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-1A1A1A ff-Akkurat_Bold">
              <FormattedMessage id="Status" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-1A1A1A ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {branchListData?.length > 0 &&
            branchListData.map((key, index) => (
              <Row className="m-0 p-0 flex items-center listAppointment">
                <Col lg={3} md={6} sm={12}>
                  <p className="p-0 m-0 fs-14 fc-1A1A1A fw-400 ff-Akkurat_Bold">
                    {key?.searched_business_name_en}
                  </p>
                  <p className="p-0 m-0 fs-12 fw-400 fc-1A1A1A ff-Akkurat">
                    {key?.address}
                  </p>
                </Col>

                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fc-1A1A1A fw-400 ff-Akkurat mt-1">
                    {key?.branch_manager_name_en}
                  </p>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center "
                  >
                    <img
                      src={mail}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-12 fc-1A1A1A fw-400 ff-Akkurat">
                      {key?.email}
                    </p>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center mt-2"
                  >
                    <img
                      src={call}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-12 fc-1A1A1A fw-400 ff-Akkurat">
                      {key?.contact_number}
                    </p>
                  </Stack>
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <SwitchInput
                    label={true}
                    checked={checked}
                    onChange={(e) => handleSwitchChange(e)}
                    color="secondary"
                  />
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center cursor-pointer"
                  >
                    <img
                      src={trash}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                      onClick={() => handleBranchDelete(key?.id)}
                    />
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                      onClick={() =>
                        navigate(providerAdminBranchesDetails(locale), {
                          state: key?.id,
                        })
                      }
                    >
                      <p className="p-0 m-0 fs-14 fc-1A1A1A fw-400 ff-Akkurat_Bold underline underline-offset-4">
                        <FormattedMessage id="View Details" />
                      </p>
                      <img
                        src={rightArrow}
                        alt="icon"
                        style={{ height: '20px', width: '20px' }}
                      />
                    </Stack>
                  </Stack>
                </Col>
              </Row>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

export default ProviderAdminBranches;
