import { postMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const ADD_COMPANY_BRANCH_PROVIDER = 'ADD_COMPANY_BRANCH_PROVIDER';
export const ADD_COMPANY_BRANCH_PROVIDER_SUCCESS =
  'ADD_COMPANY_BRANCH_PROVIDER_SUCCESS';
export const ADD_COMPANY_BRANCH_PROVIDER_FAILURE =
  'ADD_COMPANY_BRANCH_PROVIDER_FAILURE';

// Action Creators
export const fetchAddCompanyBranchProvider = () => ({
  type: ADD_COMPANY_BRANCH_PROVIDER,
});

export const fetchAddCompanyBranchProviderSuccess = (data) => ({
  type: ADD_COMPANY_BRANCH_PROVIDER_SUCCESS,
  payload: data,
});

export const fetchAddCompanyBranchProviderFailure = (error) => ({
  type: ADD_COMPANY_BRANCH_PROVIDER_FAILURE,
  payload: error,
});

// Thunk Function
export const addCompanyBranchProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchAddCompanyBranchProvider(data, external));
    try {
      const response = await postMethodWithToken(
        url.addCompanyBranchProviderSuperAdmin + external?.id + '/',
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchAddCompanyBranchProviderSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        if (response.status === 401) {
          // ClearTokenRedirectLogin(external?.navigate);
        }
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchAddCompanyBranchProviderFailure(error.message));
    }
  };
};
