import React, { useContext } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'components/navbar/navbar.scss';
import langIconEn from 'assets/images/navbar/arabicLangIcon.png';
import langIconAr from 'assets/images/navbar/englishLangIcon.png';
import { LocaleContext } from 'routes/Router';
import 'assets/styles/common.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import sidebarLogo from 'assets/images/sidebar/sidebarLogo.png';

const CustomNavbar = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale, setLocale } = useContext(LocaleContext);

  const profileData = useSelector((state) => state?.userMeReducer?.data?.data);

  const LanguageSwitcher = (value) => {
    setLocale(value);
  };

  return (
    <Navbar className={`d-flex ${locale === 'en' ? 'navbarEn' : 'navbarAr'}`}>
      <Container fluid>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <div className={`${locale === 'en' ? 'ml-5' : 'mr-5'}`}>
            <img src={sidebarLogo} alt={'logo'} />
          </div>

          <div
            className={`d-flex ${locale === 'en' ? 'ml-auto' : 'mr-auto'} items-center`}
          >
            <Nav.Link
              className="d-flex align-items-center"
              onClick={() =>
                locale === 'en'
                  ? LanguageSwitcher('ar')
                  : LanguageSwitcher('en')
              }
            >
              <img
                src={locale === 'en' ? langIconAr : langIconEn}
                alt="Language Icon"
                width="40"
                height="40"
                className={locale === 'en' ? 'mr-5' : 'ml-5'}
              />{' '}
            </Nav.Link>
            <div
              className={`d-flex align-items-center ${locale === 'en' ? 'ms-4' : 'me-4'}`}
            >
              {profileData?.profile_image === '' ? (
                <Avatar
                  className={locale === 'en' ? 'me-3' : 'ms-3'}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#D8E3FF',
                    color: '#4573B9',
                  }}
                >
                  {profileData?.username?.charAt(0)}
                </Avatar>
              ) : (
                <Avatar
                  className={locale === 'en' ? 'me-3' : 'ms-3'}
                  src={profileData?.profile_image}
                  alt="image"
                />
              )}
              <p
                className={`p-0 m-0 fs-14 fw-400 fc-404040 ff-Akkurat ${locale === 'en' ? 'me-4' : 'ms-4'}`}
              >
                {profileData?.username}
              </p>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
