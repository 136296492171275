import {
  BASIC_DETAILS,
  SELECTED_TAB,
  SUB_CATEGORY_DETAILS,
  SERVICE_DETAILS,
  SUB_SERVICE_DETAILS,
  ACTIVE_INDEX,
} from './action';

const initialState = {
  editCategory: null,
  subCategoryData: null,
  serviceDetails: null,
  subServiceDetails: null,
  selectedTab: 0,
  activeIndex: 0,
};

const basicDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BASIC_DETAILS:
      return {
        ...state,
        editCategory: action.payload,
      };
    case ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.payload,
      };
    case SUB_CATEGORY_DETAILS:
      return {
        ...state,
        subCategoryData: action.payload,
      };
    case SERVICE_DETAILS:
      return {
        ...state,
        serviceDetails: action.payload,
      };
    case SUB_SERVICE_DETAILS:
      return {
        ...state,
        subServiceDetails: action.payload,
      };
    case SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    default:
      return state;
  }
};

export default basicDetailsReducer;
