import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Calendar from '@mui/icons-material/Event';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => (theme === 'dark' ? '#fff' : '#ccc')};
  border-radius: 0px;
  padding: 8px;
  background-color: ${({ theme }) => (theme === 'dark' ? '#333' : '#fff')};
  gap: 8px; /* Space between inputs */
  height: 50px;

  .MuiStack-root.MuiMultiInputDateRangeField-root {
    width: 100% !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    .MuiSvgIcon-root {
      margin-right: 10px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    &:focus {
      border-color: transparent !important;
    }
  }
`;

const ReusableDateRangePicker = ({ value, onChange, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledContainer>
        <DateRangePicker
          value={value}
          onChange={onChange}
          localeText={{ start: '', end: '' }} // Removes placeholder labels
          slotProps={{
            field: {
              dateSeparator: '|',
              sx: {
                '& .MuiDateRangePicker-separator': {
                  height: '100px', // Increase the height of the separator
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              },
            },
            textField: { InputProps: { startAdornment: <Calendar /> } },
          }}
          {...props}
        />
      </StyledContainer>
    </LocalizationProvider>
  );
};

ReusableDateRangePicker.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ReusableDateRangePicker;
