import React, { useContext, useEffect, useState } from 'react';
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Stack } from 'react-bootstrap';
import { LocaleContext } from 'routes/Router';
import ArrowLeftIcon from 'assets/images/arrow-left.png';
import AddIcon from 'assets/images/Dashboard/add.png';
import CheckBoxInput from 'components/common/Input/CheckBoxInput';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import ReusableButton from 'components/common/Button';
import CustomTabs from 'components/common/CustomTabs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { providerAdminAppointments } from 'routes/AppUrls';
import moment from 'moment';
import { updateAppointmentsCallAPI } from 'store/ProviderAdmin/updateAppointments/action';
import ToastNotification from 'components/common/Toast';
import { addAppointmentsCallAPI } from 'store/ProviderAdmin/addAppointments/action';

const EditSchdeule = () => {
  const { locale } = useContext(LocaleContext);
  const [conf, setConf] = ToastNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const [tab, setTab] = useState(0);

  const getAppointmentsData = useSelector(
    (state) => state?.getAppointmentsReducer?.data?.data
  );

  const tabData = [
    {
      label: intl.formatMessage({ id: 'Mon' }),
      slug: intl.formatMessage({ id: 'monday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      breaks: [{ break_start_time: '', break_end_time: '' }],
      isShow: false,
    },
    {
      label: intl.formatMessage({ id: 'Tue' }),
      slug: intl.formatMessage({ id: 'tuesday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      breaks: [{ break_start_time: '', break_end_time: '' }],
      isShow: false,
    },
    {
      label: intl.formatMessage({ id: 'Wed' }),
      slug: intl.formatMessage({ id: 'wednesday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      breaks: [{ break_start_time: '', break_end_time: '' }],
      isShow: false,
    },
    {
      label: intl.formatMessage({ id: 'Thu' }),
      slug: intl.formatMessage({ id: 'thursday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      breaks: [{ break_start_time: '', break_end_time: '' }],
      isShow: false,
    },
    {
      label: intl.formatMessage({ id: 'Fri' }),
      slug: intl.formatMessage({ id: 'friday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      breaks: [{ break_start_time: '', break_end_time: '' }],
      isShow: false,
    },
    {
      label: intl.formatMessage({ id: 'Sat' }),
      slug: intl.formatMessage({ id: 'saturday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      breaks: [{ break_start_time: '', break_end_time: '' }],
      isShow: false,
    },
    {
      label: intl.formatMessage({ id: 'Sun' }),
      slug: intl.formatMessage({ id: 'sunday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      breaks: [{ break_start_time: '', break_end_time: '' }],
      isShow: false,
    },
  ];

  const handleTabChange = (val) => {
    setTab(val);
  };

  ////////////TabShiftDetails/////////////////////////

  function convertTo24HourFormat(time12h) {
    return moment(time12h, ['h:mm A']).format('HH:mm');
  }

  const [tabDetails, setTabDetails] = useState(() => {
    if (getAppointmentsData?.length > 0) {
      return tabData.map((tab) => {
        const matchingAppointment = getAppointmentsData.find(
          (appointment) => appointment?.day_of_week?.slug === tab.slug
        );
        const matchingAppointmentIndex = getAppointmentsData.findIndex(
          (appointment) => appointment?.day_of_week?.slug === tab.slug
        );
        if (matchingAppointment) {
          return {
            ...tab,
            isHoliday:
              getAppointmentsData?.[matchingAppointmentIndex]?.is_holiday,
            id: getAppointmentsData?.[matchingAppointmentIndex]?.id,
            shift: {
              start: convertTo24HourFormat(
                getAppointmentsData?.[matchingAppointmentIndex]?.start_time
              ),
              end: convertTo24HourFormat(
                getAppointmentsData?.[matchingAppointmentIndex]?.end_time
              ),
            },
            breaks: getAppointmentsData?.[
              matchingAppointmentIndex
            ]?.schedule_breaks?.map((bItem) => ({
              break_start_time: convertTo24HourFormat(bItem?.break_start_time),
              break_end_time: convertTo24HourFormat(bItem?.break_end_time),
            })) || [{ break_start_time: '', break_end_time: '' }],
            isShow: true,
          };
        }
        return tab;
      });
    }
    return tabData;
  });

  const handleShiftChange = (tabIndex, field, value) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].shift[field] = value;
    setTabDetails(updatedDetails);
  };

  const handleBreakChange = (tabIndex, breakIndex, field, value) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].breaks[breakIndex][field] = value;
    setTabDetails(updatedDetails);
  };

  const handleAddBreak = (tabIndex) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].breaks.push({
      break_start_time: '',
      break_end_time: '',
    });
    setTabDetails(updatedDetails);
  };

  const handleSaveDetails = (tabValue) => {
    const indicesToSave = tabDetails.findIndex(
      (tab) => tab.label === tabValue.label
    );

    if (tabDetails?.[indicesToSave]?.id) {
      let body = {
        is_holiday: tabDetails[indicesToSave]?.isHoliday,
        day_of_week: tabDetails[indicesToSave]?.slug,
      };
      if (!tabDetails[indicesToSave]?.isHoliday) {
        body.start_time = tabDetails?.[indicesToSave]?.shift?.start;
        body.end_time = tabDetails?.[indicesToSave]?.shift?.end;
        body.schedule_breaks = tabDetails?.[indicesToSave]?.breaks;
      }

      dispatch(
        updateAppointmentsCallAPI(body, {
          id: tabDetails?.[indicesToSave]?.id,
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSuccess(res),
        })
      );
    } else {
      let body = {
        schedules: [
          {
            is_holiday: tabDetails[indicesToSave]?.isHoliday,
            day_of_week: tabDetails[indicesToSave]?.slug,
          },
        ],
      };
      if (!tabDetails[indicesToSave]?.isHoliday) {
        body.schedules[0].start_time =
          tabDetails?.[indicesToSave]?.shift?.start;
        body.schedules[0].end_time = tabDetails?.[indicesToSave]?.shift?.end;
        body.schedules[0].schedule_breaks = tabDetails?.[indicesToSave]?.breaks;
      }
      dispatch(
        addAppointmentsCallAPI(body, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSuccess(res),
        })
      );
    }
  };

  const handleSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setTimeout(() => {
      navigate(providerAdminAppointments(locale));
    }, 1000);
  };

  const handleCreateAppointments = (tabValue, tabIndex) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].isShow = true;
    setTabDetails(updatedDetails);
  };

  const tabShiftDetails = (tabValue, tabIndex) => {
    const currentTabDetails = tabDetails[tabIndex];
    const isHolidayDisabled = currentTabDetails.isHoliday ? 'opacity-50' : '';

    return (
      <>
        {currentTabDetails?.isShow ? (
          <>
            <div className="mt-3">
              <CheckBoxInput
                label={<FormattedMessage id="Mark as Holiday" />}
                size="14"
                fontWeight="500"
                onChange={(e) => {
                  const updatedDetails = [...tabDetails];
                  updatedDetails[tabIndex].isHoliday = e.target.checked;
                  setTabDetails(updatedDetails);
                }}
                checked={currentTabDetails.isHoliday}
              />
            </div>

            <h3
              className={`p-0 m-0 mt-4 fs-16 fc-292D32 ff-Akkurat_Bold ${isHolidayDisabled}`}
            >
              <FormattedMessage id="Shift Details" />
            </h3>

            <Row className={`m-0 p-0 ${isHolidayDisabled}`}>
              <Col md={12} lg={6} className="">
                <LabelWithInput
                  label={<FormattedMessage id="Start Time" />}
                  type="time"
                  value={currentTabDetails.shift.start}
                  disabled={currentTabDetails.isHoliday}
                  onChange={(e) =>
                    handleShiftChange(tabIndex, 'start', e.target.value)
                  }
                />
              </Col>

              <Col md={12} lg={6} className="">
                <LabelWithInput
                  label={<FormattedMessage id="End Time" />}
                  type="time"
                  value={currentTabDetails.shift.end}
                  disabled={currentTabDetails.isHoliday}
                  onChange={(e) =>
                    handleShiftChange(tabIndex, 'end', e.target.value)
                  }
                />
              </Col>
            </Row>

            <h3
              className={`p-0 m-0 mt-4 fs-16 fc-292D32 ff-Akkurat_Bold ${isHolidayDisabled}`}
            >
              <FormattedMessage id="Break(s)" />
            </h3>

            <Row className={`m-0 p-0 ${isHolidayDisabled}`}>
              {currentTabDetails.breaks.map((breakTime, index) => (
                <>
                  <h3 className="p-0 m-0 mt-4 fs-14 fc-292D32 ff-Akkurat ">
                    <FormattedMessage id={`Break`} />
                    {index + 1}
                  </h3>

                  <Col md={12} lg={6} className="">
                    <LabelWithInput
                      label={<FormattedMessage id="Start Time" />}
                      type="time"
                      format="h:mm a"
                      value={breakTime.break_start_time}
                      disabled={currentTabDetails.isHoliday}
                      onChange={(e) =>
                        handleBreakChange(
                          tabIndex,
                          index,
                          'break_start_time',
                          e.target.value
                        )
                      }
                    />
                  </Col>

                  <Col md={12} lg={6} className="">
                    <LabelWithInput
                      label={<FormattedMessage id="End Time" />}
                      type="time"
                      format="h:mm a"
                      value={breakTime.break_end_time}
                      disabled={currentTabDetails.isHoliday}
                      onChange={(e) =>
                        handleBreakChange(
                          tabIndex,
                          index,
                          'break_end_time',
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </>
              ))}
            </Row>

            <Row className={`m-0 p-0 mt-3 ${isHolidayDisabled}`}>
              <ReusableButton
                fullWidth={false}
                variant="outlined"
                color="#1A1A1A"
                fontSize="14px"
                fontWeight="500"
                padding="12px 25px"
                height="40px"
                bgColor="transparent"
                brColor="#DDDDDD"
                fontFamily="Akkuat"
                disabled={currentTabDetails.isHoliday}
                onClick={() => handleAddBreak(tabIndex)}
              >
                <img
                  src={AddIcon}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                  className="me-2"
                />
                <FormattedMessage id="Add break" />
              </ReusableButton>
            </Row>

            <Row className="m-0 p-0 mt-4 flex text-end items-center justify-end">
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center justify-end p-0"
              >
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#1A1A1A"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 50px"
                  height="44px"
                  bgColor="transparent"
                  brColor="#DDDDDD"
                  fontFamily="Akkurat"
                >
                  <FormattedMessage id="Cancel" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#FFFFFF"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 50px"
                  height="44px"
                  bgColor="#1A1A1A"
                  brColor="#1A1A1A"
                  fontFamily="Akkurat"
                  onClick={() => handleSaveDetails(tabValue)}
                >
                  <FormattedMessage id="Save" />
                </ReusableButton>
              </Stack>
            </Row>
          </>
        ) : (
          <>
            <div className="flex-col items-center justify-center text-center mt-5 pt-5 mb-5 pb-5">
              <div className="mt-5 pt-5">
                <p className="p-0 m-0 fs-16 fc-#1A1A1A ff-Akkurat">
                  <FormattedMessage id="Show your availability to the customer" />
                </p>
              </div>
              <div className="mt-4 mb-5 pb-5">
                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#FFFFFF"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 50px"
                  height="44px"
                  bgColor="#1A1A1A"
                  brColor="#1A1A1A"
                  fontFamily="Akkurat"
                  onClick={() => handleCreateAppointments(tabValue, tabIndex)}
                >
                  <img
                    src={AddIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="me-2"
                  />
                  <FormattedMessage id="Create New" />
                </ReusableButton>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  ////////////TabShiftDetails/////////////////////////

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 family_outfit_semiBold_600  m-0">
          <FormattedMessage id="Schedule" />
        </h2>

        <div className="flex gap-4 p-0 mt-3">
          <Row className="p-0 m-0 w-100">
            <Col
              md={12}
              lg={12}
              className=" border-1 bColor-FFFFFF bc-FFFFFF p-4"
            >
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center cursor-pointer"
                onClick={() => navigate(providerAdminAppointments(locale))}
              >
                <img
                  src={ArrowLeftIcon}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                />
                <p className="p-0 m-0 fs-20 fc-#1A1A1A ff-Akkurat_Bold ">
                  <FormattedMessage id="Edit Timings" />
                </p>
              </Stack>

              <div className="mt-3 w-100">
                <CustomTabs
                  tabs={tabData}
                  value={tab}
                  onChange={handleTabChange}
                  tabStyles={{
                    fontSize: '18px',
                    color: '#787878',
                    selectedColor: '#1A1A1A ',
                  }}
                  indicatorColor="#1A1A1A"
                />
              </div>

              {tabData.map((k, i) => tab === i && tabShiftDetails(k, i))}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default EditSchdeule;
