import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminProvider } from 'routes/AppUrls';
import ReusableTabs from 'components/common/Tabs';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import SelectInput from 'components/common/Input/SelectInput';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import crossIcon from 'assets/images/provider/crossIcon.png';
import ReusableButton from 'components/common/Button';
import { addSalonProviderCallAPI } from 'store/SuperAdmin/addSalonProvider/action';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { addCompanyProviderCallAPI } from 'store/SuperAdmin/addCompanyProvider/action';
import CheckValidation from 'utils/Validation';
import { countryCityDropdownCallAPI } from 'store/CountryCityList/action';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { imageUploadCallAPI } from 'store/ImageUpload/action';

const AddProvider = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const { locale } = useContext(LocaleContext);

  const [tab, setTab] = useState(1);
  const salonBrandImageFileInputRef = useRef();
  const salonImageRef = useRef();
  const phoneNumberInputRef = useRef(null);
  const alternatePhoneNumberInputRef = useRef(null);
  const bussinessPhoneNumberInputRef = useRef(null);
  const [isError, setIsError] = useState(null);

  const [salonBrandImageFile, setSalonBrandImageFile] = useState(null);
  const [salonImages, setSalonImages] = useState([]);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isAlternatePhoneNumberValid, setIsAlternatePhoneNumberValid] =
    useState(true);
  const [isBussinessPhoneNumberValid, setIsBussinessPhoneNumberValid] =
    useState(true);

  const countryCityData = useSelector(
    (state) => state?.countryCityDropdownReducer?.data?.data
  );

  const [formValue, setFormValue] = useState({
    ownerName: '',
    searchBussinessName: '',
    addressArea: '',
    country: '',
    city: '',
    phoneNumber: '',
    countryCode: '+974',
    alternatePhoneNumber: '',
    brandImage: '',
    countryCodeAlternateNumber: '+974',
    email: '',
    alternateEmail: '',
    about: '',
    bussinessEmail: '',
    bussinessImages: [],
    countryCodeBussinessPhoneNumber: '+974',
    bussinessPhoneNumber: '',
  });

  const [formId, setFormId] = useState({
    country: '',
    city: '',
  });

  useEffect(() => {
    getCountryCityList();
  }, []);

  const getCountryCityList = () => {
    dispatch(countryCityDropdownCallAPI({}, { naviagte: navigate }));
  };

  const tabData = [
    { label: intl.formatMessage({ id: 'Salon' }) },
    { label: intl.formatMessage({ id: 'Company' }) },
  ];

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Providers" />,
      Link: superAdminProvider(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Add a Provider" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const handleTabChange = (val) => {
    setTab(val);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value?.name });
    setFormId({ ...formId, [name]: value?.id });
  };

  const handleSalonBrandImageFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSuccess(res),
        })
      );
      setSalonBrandImageFile(URL.createObjectURL(file));
    }
  };

  const handleSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setFormValue({ ...formValue, ['brandImage']: res?.data?.data?.[0]?.id });
  };

  const handleSalonImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append('filePath', file);
      formData.append('fileType', 'I');
      dispatch(
        imageUploadCallAPI(formData, {
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSalonImagesSuccess(res),
        })
      );
      setSalonImages([...salonImages, URL.createObjectURL(file)]);
    }
  };

  const handleSalonImagesSuccess = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setFormValue({
      ...formValue,
      ['bussinessImages']: [
        ...formValue?.bussinessImages,
        res?.data?.data?.[0]?.id,
      ],
    });
  };

  const handleSalonBrandImageUpload = () => {
    salonBrandImageFileInputRef.current.click();
  };

  const handleSalonImageUpload = () => {
    salonImageRef.current.click();
  };

  const handleDeleteSalonImage = (data, index) => {
    const filterImages = salonImages?.filter((value) => value !== data);
    setSalonImages(filterImages);
  };

  const handleSalonSubmit = () => {
    const {
      ownerName,
      searchBussinessName,
      country,
      city,
      phoneNumber,
      email,
      bussinessEmail,
      bussinessPhoneNumber,
    } = formValue;
    const error = CheckValidation({
      ownerName,
      searchBussinessName,
      country,
      city,
      phoneNumber,
      email,
      bussinessEmail,
      bussinessPhoneNumber,
    });
    setIsError(error);
    if (error.submit) {
      let body = {
        business_type: tab === 1 ? 'salon' : 'company',
        owner_name_en: formValue?.ownerName,
        owner_name_ar: formValue?.ownerName,
        searched_business_name_en: formValue?.searchBussinessName,
        searched_business_name_ar: formValue?.searchBussinessName,
        address: formValue?.addressArea,
        country: formId?.country,
        city: formId?.city,
        contact_number: formValue?.phoneNumber,
        is_contact_verified: false,
        alternate_contact_number: formValue?.alternatePhoneNumber,
        is_alternate_contact_verified: false,
        email: formValue?.email,
        is_email_verified: false,
        alternate_email: formValue?.alternateEmail,
        is_alternate_email_verified: false,
        about_information_en: formValue?.about,
        about_information_ar: formValue?.about,
        profile_photo: formValue?.brandImage,
        bussiness_images: formValue?.bussinessImages,
        about_bussiness: {
          email: formValue?.bussinessEmail,
          contact_number: formValue?.bussinessPhoneNumber,
        },
      };
      dispatch(
        addSalonProviderCallAPI(body, {
          run: true,
          handleFunction: (res) => handleRedirect(res),
          navigate: navigate,
        })
      );
    }
  };

  const handleCompanySubmit = () => {
    const {
      ownerName,
      searchBussinessName,
      country,
      city,
      phoneNumber,
      email,
    } = formValue;
    const error = CheckValidation({
      ownerName,
      searchBussinessName,
      country,
      city,
      phoneNumber,
      email,
    });
    setIsError(error);
    if (error.submit) {
      let body = {
        business_type: tab === 1 ? 'salon' : 'company',
        owner_name_en: formValue?.ownerName,
        owner_name_ar: formValue?.ownerName,
        searched_business_name_en: formValue?.searchBussinessName,
        searched_business_name_ar: formValue?.searchBussinessName,
        address: formValue?.addressArea,
        country: formId?.country,
        city: formId?.city,
        contact_number: formValue?.phoneNumber,
        is_contact_verified: false,
        alternate_contact_number: formValue?.alternatePhoneNumber,
        is_alternate_contact_verified: false,
        email: formValue?.email,
        is_email_verified: false,
        alternate_email: formValue?.alternateEmail,
        is_alternate_email_verified: false,
        about_information_en: formValue?.about,
        about_information_ar: formValue?.about,
        profile_photo: formValue?.brandImage,
        bussiness_images: formValue?.bussinessImages,
      };
      dispatch(
        addCompanyProviderCallAPI(body, {
          run: true,
          handleFunction: (res) => handleRedirect(res),
          navigate: navigate,
        })
      );
    }
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    navigate(superAdminProvider(locale));
  };

  const handleCountryCodeChange = (value, name) => {
    if (name === 'phoneNumber') {
      setFormValue({ ...formValue, countryCode: `+${value}` });
    } else if (name === 'alternatePhoneNumber') {
      setFormValue({ ...formValue, countryCodeAlternateNumber: `+${value}` });
    } else {
      setFormValue({
        ...formValue,
        countryCodeBussinessPhoneNumber: `+${value}`,
      });
    }
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumberValue = e.target.value;
    setFormValue((prev) => ({
      ...prev,
      [e.target.name]: phoneNumberValue,
    }));

    if (e.target.name === 'phoneNumber') {
      const fullPhoneNumber = `${formValue?.countryCode}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsPhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsPhoneNumberValid(false);
      }
    } else if (e.target.name === 'alternatePhoneNumber') {
      const fullPhoneNumber = `${formValue?.countryCodeAlternateNumber}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsAlternatePhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsAlternatePhoneNumberValid(false);
      }
    } else {
      const fullPhoneNumber = `${formValue?.countryCodeBussinessPhoneNumber}${phoneNumberValue}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);
      if (parsedPhoneNumber) {
        setIsBussinessPhoneNumberValid(parsedPhoneNumber.isValid());
      } else {
        setIsBussinessPhoneNumberValid(false);
      }
    }
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
            <FormattedMessage id="Provider Details" />
          </h2>

          <h5 className="fc-292D32 fs-18 fw-400 ff-Akkurat_Bold m-0 mt-4">
            <FormattedMessage id="Business Details" />
          </h5>

          <p className="fc-292D32 fs-14 fw-400 ff-Akkurat m-0 mt-3">
            <FormattedMessage id="Business Type" />
          </p>

          <Col md={12} lg={6} xl={6} xxl={4} className="mt-2">
            <ReusableTabs
              tabs={tabData}
              defaultTab={1}
              currentTab={(val) => handleTabChange(val)}
              hasBorder={'#DDDDDD'}
            />
          </Col>

          {tab === 1 && (
            <>
              <Row className="m-0 p-0">
                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Owner Name" />}
                    placeholder={intl.formatMessage({ id: 'Owner Name' })}
                    name="ownerName"
                    type="text"
                    value={formValue?.ownerName}
                    onChange={handleChange}
                    errorText={
                      isError &&
                      isError.ownerName.error &&
                      isError.ownerName.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Search business name" />}
                    placeholder={intl.formatMessage({
                      id: 'Search business name',
                    })}
                    name="searchBussinessName"
                    type="text"
                    value={formValue?.searchBussinessName}
                    onChange={handleChange}
                    errorText={
                      isError &&
                      isError.searchBussinessName.error &&
                      isError.searchBussinessName.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={
                      <FormattedMessage id="Address of the serving area" />
                    }
                    placeholder={intl.formatMessage({
                      id: 'Address of the serving area',
                    })}
                    name="addressArea"
                    type="text"
                    value={formValue?.addressArea}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="Country" />}
                    placeholder={intl.formatMessage({ id: 'Country' })}
                    name={'country'}
                    value={formValue?.country}
                    data={countryCityData}
                    onChange={(e) => handleInputChange(e)}
                    errorText={
                      isError &&
                      isError.country.error &&
                      isError.country.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="City" />}
                    placeholder={intl.formatMessage({ id: 'City' })}
                    name={'city'}
                    value={formValue?.city}
                    data={
                      countryCityData?.find(
                        (value) => value?.name === formValue?.country
                      )?.city || []
                    }
                    onChange={(e) => handleInputChange(e)}
                    errorText={
                      isError &&
                      isError.country.error &&
                      isError.country.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label={<FormattedMessage id="Contact Number" />}
                    country={'qa'}
                    value={formValue?.countryCode}
                    name={'phoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'phoneNumber')
                    }
                    onPhoneNumberChange={handlePhoneNumberChange}
                    phoneNumber={formValue.phoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={
                      isError &&
                      isError.phoneNumber.error &&
                      isError.phoneNumber.errorMessage
                    }
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label={<FormattedMessage id="Alternate Contact Number" />}
                    country={'qa'}
                    value={formValue?.countryCodeAlternateNumber}
                    name={'alternatePhoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'alternatePhoneNumber')
                    }
                    onPhoneNumberChange={handlePhoneNumberChange}
                    phoneNumber={formValue.alternatePhoneNumber}
                    phoneNumberRef={alternatePhoneNumberInputRef}
                    isPhoneNumberValid={isAlternatePhoneNumberValid}
                    // isError={isError}
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Email ID" />}
                    placeholder={intl.formatMessage({ id: 'Email ID' })}
                    name="email"
                    type="text"
                    value={formValue?.email}
                    onChange={handleChange}
                    errorText={
                      isError &&
                      isError.email.error &&
                      isError.email.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Alternate Email ID" />}
                    placeholder={intl.formatMessage({
                      id: 'Alternate Email ID',
                    })}
                    name="alternateEmail"
                    type="text"
                    value={formValue?.alternateEmail}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="About/Information" />}
                    placeholder={intl.formatMessage({
                      id: 'About/Information',
                    })}
                    height="100"
                    multiline
                    name="about"
                    value={formValue?.about}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>

                <input
                  ref={salonBrandImageFileInputRef}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  disabled={false}
                  style={{ display: 'none' }}
                  onChange={handleSalonBrandImageFileChange}
                />

                <div className="mt-4">
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Photos" />
                  </p>
                  <Stack
                    direction="horizontal"
                    gap={4}
                    className="items-center mt-4"
                  >
                    <div className="AvtarImageIcon">
                      {salonBrandImageFile && (
                        <img
                          src={salonBrandImageFile}
                          alt={'icon'}
                          style={{
                            height: '88px',
                            width: '88px',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      <img
                        src={ProfileIcon}
                        alt="icon"
                        style={{ height: '32px', width: '32px' }}
                        className="profileIcon"
                        onClick={handleSalonBrandImageUpload}
                      />
                    </div>
                    <div>
                      <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        <FormattedMessage id="Upload Brand Image" />
                      </p>
                      <p className="p-0 m-0 fs-14 fc-9A9A9A fw-400 ff-Akkurat">
                        <FormattedMessage id="A picture will help people recognize you" />
                      </p>
                    </div>
                  </Stack>
                </div>

                <Col xs={12} md={12} className="mt-3">
                  <div className="flex justify-between items-center">
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="Images" />
                    </p>
                    {salonImages?.length > 0 && (
                      <ReusableButton
                        fullWidth={false}
                        variant="outlined"
                        color="#292D32"
                        fontSize="16px"
                        fontWeight="400"
                        padding="12px 25px"
                        height="44px"
                        bgColor="transparent"
                        fontFamily="Akkurat"
                        onClick={handleSalonImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                          className="me-2"
                        />
                        <FormattedMessage id="Add Images" />
                      </ReusableButton>
                    )}
                  </div>

                  <input
                    ref={salonImageRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    disabled={false}
                    style={{ display: 'none' }}
                    onChange={handleSalonImageChange}
                  />

                  {salonImages?.length <= 0 ? (
                    <div className="mt-2 bussinessImages flex justify-center align-center">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={handleSalonImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                          <FormattedMessage id="AddImageEn" />
                        </p>
                      </Stack>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-2 mt-3">
                      {salonImages.map((key, index) => (
                        <div className="relative">
                          <img
                            src={key}
                            alt="image"
                            style={{ height: '122px', width: '246px' }}
                          />
                          <img
                            src={crossIcon}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            className="absolute top-2 right-2 cursor-pointer"
                            onClick={() => handleDeleteSalonImage(key, index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <p className="m-0 p-0 mt-4 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="About Business" />
                  </p>
                </Col>

                <Col xs={12} md={6} className="mt-1">
                  <LabelWithInput
                    label={<FormattedMessage id="Email" />}
                    placeholder={intl.formatMessage({ id: 'Email' })}
                    name="bussinessEmail"
                    type="text"
                    value={formValue?.bussinessEmail}
                    onChange={handleChange}
                    errorText={
                      isError &&
                      isError.bussinessEmail.error &&
                      isError.bussinessEmail.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-1">
                  <PhoneNumberInput
                    label={<FormattedMessage id="Contact Number" />}
                    country={'qa'}
                    value={formValue?.countryCodeBussinessPhoneNumber}
                    name={'bussinessPhoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'bussinessPhoneNumber')
                    }
                    onPhoneNumberChange={handlePhoneNumberChange}
                    phoneNumber={formValue.bussinessPhoneNumber}
                    phoneNumberRef={bussinessPhoneNumberInputRef}
                    isPhoneNumberValid={isBussinessPhoneNumberValid}
                    isError={
                      isError &&
                      isError.bussinessPhoneNumber.error &&
                      isError.bussinessPhoneNumber.errorMessage
                    }
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>
              </Row>

              <Row className="mt-4 items-end m-0 p-0">
                <div className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => navigate(superAdminProvider(locale))}
                    >
                      <FormattedMessage id="Cancel" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                      onClick={() => handleSalonSubmit()}
                    >
                      <FormattedMessage id="Send Invitation" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>
            </>
          )}

          {tab === 2 && (
            <>
              <Row className="m-0 p-0">
                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Owner Name" />}
                    placeholder={intl.formatMessage({ id: 'Owner Name' })}
                    name="ownerName"
                    type="text"
                    value={formValue?.ownerName}
                    onChange={handleChange}
                    errorText={
                      isError &&
                      isError.ownerName.error &&
                      isError.ownerName.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Search business name" />}
                    placeholder={intl.formatMessage({
                      id: 'Search business name',
                    })}
                    name="searchBussinessName"
                    type="text"
                    value={formValue?.searchBussinessName}
                    onChange={handleChange}
                    errorText={
                      isError &&
                      isError.searchBussinessName.error &&
                      isError.searchBussinessName.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={
                      <FormattedMessage id="Address of the serving area" />
                    }
                    placeholder={intl.formatMessage({
                      id: 'Address of the serving area',
                    })}
                    name="addressArea"
                    type="text"
                    value={formValue?.addressArea}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="Country" />}
                    placeholder={intl.formatMessage({ id: 'Country' })}
                    name={'country'}
                    value={formValue?.country}
                    data={countryCityData}
                    onChange={(e) => handleInputChange(e)}
                    errorText={
                      isError &&
                      isError.country.error &&
                      isError.country.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="City" />}
                    placeholder={intl.formatMessage({ id: 'City' })}
                    name={'city'}
                    value={formValue?.city}
                    data={
                      countryCityData?.find(
                        (value) => value?.name === formValue?.country
                      )?.city || []
                    }
                    onChange={(e) => handleInputChange(e)}
                    errorText={
                      isError &&
                      isError.country.error &&
                      isError.country.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label={<FormattedMessage id="Contact Number" />}
                    country={'qa'}
                    value={formValue?.countryCode}
                    name={'phoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'phoneNumber')
                    }
                    onPhoneNumberChange={handlePhoneNumberChange}
                    phoneNumber={formValue.phoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={
                      isError &&
                      isError.phoneNumber.error &&
                      isError.phoneNumber.errorMessage
                    }
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label={<FormattedMessage id="Alternate Contact Number" />}
                    country={'qa'}
                    value={formValue?.countryCodeAlternateNumber}
                    name={'alternatePhoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'alternatePhoneNumber')
                    }
                    onPhoneNumberChange={handlePhoneNumberChange}
                    phoneNumber={formValue.alternatePhoneNumber}
                    phoneNumberRef={alternatePhoneNumberInputRef}
                    isPhoneNumberValid={isAlternatePhoneNumberValid}
                    // isError={isError}
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Email ID" />}
                    placeholder={intl.formatMessage({ id: 'Email ID' })}
                    name="email"
                    type="text"
                    value={formValue?.email}
                    onChange={handleChange}
                    errorText={
                      isError &&
                      isError.email.error &&
                      isError.email.errorMessage
                    }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Alternate Email ID" />}
                    placeholder={intl.formatMessage({
                      id: 'Alternate Email ID',
                    })}
                    name="alternateEmail"
                    type="text"
                    value={formValue?.alternateEmail}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="About/Information" />}
                    placeholder={intl.formatMessage({
                      id: 'About/Information',
                    })}
                    height="100"
                    multiline
                    name="about"
                    value={formValue?.about}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>

                <input
                  ref={salonBrandImageFileInputRef}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  disabled={false}
                  style={{ display: 'none' }}
                  onChange={handleSalonBrandImageFileChange}
                />

                <div className="mt-4">
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Photos" />
                  </p>
                  <Stack
                    direction="horizontal"
                    gap={4}
                    className="items-center mt-4"
                  >
                    <div className="AvtarImageIcon">
                      {salonBrandImageFile && (
                        <img
                          src={salonBrandImageFile}
                          alt={'icon'}
                          style={{
                            height: '88px',
                            width: '88px',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      <img
                        src={ProfileIcon}
                        alt="icon"
                        style={{ height: '32px', width: '32px' }}
                        className="profileIcon"
                        onClick={handleSalonBrandImageUpload}
                      />
                    </div>
                    <div>
                      <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        <FormattedMessage id="Upload Brand Image" />
                      </p>
                      <p className="p-0 m-0 fs-14 fc-9A9A9A fw-400 ff-Akkurat">
                        <FormattedMessage id="A picture will help people recognize you" />
                      </p>
                    </div>
                  </Stack>
                </div>

                <Col xs={12} md={12} className="mt-3">
                  <div className="flex justify-between items-center">
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="Images" />
                    </p>
                    {salonImages?.length > 0 && (
                      <ReusableButton
                        fullWidth={false}
                        variant="outlined"
                        color="#292D32"
                        fontSize="16px"
                        fontWeight="400"
                        padding="12px 25px"
                        height="44px"
                        bgColor="transparent"
                        fontFamily="Akkurat"
                        onClick={handleSalonImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                          className="me-2"
                        />
                        <FormattedMessage id="Add Images" />
                      </ReusableButton>
                    )}
                  </div>

                  <input
                    ref={salonImageRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    disabled={false}
                    style={{ display: 'none' }}
                    onChange={handleSalonImageChange}
                  />

                  {salonImages?.length <= 0 ? (
                    <div className="mt-2 bussinessImages flex justify-center align-center">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={handleSalonImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                          <FormattedMessage id="AddImageEn" />
                        </p>
                      </Stack>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-2 mt-3">
                      {salonImages.map((key, index) => (
                        <div className="relative">
                          <img
                            src={key}
                            alt="image"
                            style={{ height: '122px', width: '246px' }}
                          />
                          <img
                            src={crossIcon}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            className="absolute top-2 right-2 cursor-pointer"
                            onClick={() => handleDeleteSalonImage(key, index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>

              <Row className="mt-4 items-end m-0 p-0">
                <div className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => navigate(superAdminProvider(locale))}
                    >
                      <FormattedMessage id="Cancel" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                      onClick={() => handleCompanySubmit()}
                    >
                      <FormattedMessage id="Send Invitation" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default AddProvider;
