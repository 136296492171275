import React, { useContext, useState } from 'react';
import { Row, Col, Stack, Image } from 'react-bootstrap';
import DragIndicatorIcon from 'assets/images/Category/DragIndicatorIcon.png';
import CheckBoxInput from 'components/common/Input/CheckBoxInput';
import CaretUpIcon from 'assets/images/Category/CaretUp.png';
import CaretDownIcon from 'assets/images/Category/CaretDown.png';
import EditUnderlineIcon from 'assets/images/Category/editUnderlineIcon.png';
import DescriptionIcon from 'assets/images/Category/DescriptionIcon.png';
import DeleteIcon from 'assets/images/Category/DeleteIcon.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormattedMessage } from 'react-intl';
import CustomPopover from 'components/common/CustomPopover';
import { LocaleContext } from 'routes/Router';

const SubServiceList = ({ services, onEdit, onDelete }) => {
  const { locale } = useContext(LocaleContext);
  const [expanded, setExpanded] = useState({});
  const [checkedItems, setCheckedItems] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewData, setViewData] = useState('');

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleCheckboxChange = (e, id) => {
    const { name, checked } = e.target;
    setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handlePopoverClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setViewData(item);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const PopoverData = [
    {
      label: <FormattedMessage id="Edit Service" />,
      icon: <Image src={DescriptionIcon} style={{ marginRight: '5px' }} />,
      onClick: () => onEdit(viewData),
    },
    {
      label: <FormattedMessage id="Delete" />,
      icon: <Image src={DeleteIcon} style={{ marginRight: '5px' }} />,
      onClick: () => onDelete(viewData),
    },
  ];

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Row className="m-0 p-0 mt-3">
      {services.map((service, index) => (
        <Row
          key={service.id}
          className={`m-0 p-0  ${services?.length === index + 1 ? 'pt-4' : 'border-b py-4'}`}
        >
          {onEdit ? (
            <Col md={6} className="items-center">
              <div className="flex items-center gap-2">
                <img
                  src={DragIndicatorIcon}
                  alt={'icon'}
                  style={{ height: '32px', width: '32px' }}
                />
                <CheckBoxInput
                  height={'32px'}
                  onChange={(e) => handleCheckboxChange(e, service.id)}
                  checked={expanded[service.id]}
                />
                <span className="service-name font-bold">
                  {service.product_name_en}
                </span>
              </div>
            </Col>
          ) : (
            <Col md={6} className="items-center">
              <p className="service-name font-bold">{service.name}</p>
            </Col>
          )}

          <Col md={3}>
            <div className="flex items-center gap-5">
              <span className="service-price text-gray-600">
                {service.price} {service.currency}
              </span>
              <span className="service-time text-gray-600">
                {service.time_required_in_mints} min
              </span>
            </div>
          </Col>

          <Col md={3} className="">
            <Stack
              direction="horizontal"
              gap={4}
              className="items-center justify-end"
            >
              <img
                src={EditUnderlineIcon}
                alt="icon"
                style={{ height: '24px', width: '24px' }}
              />
              {!service?.is_added_by_admin && (
                <div>
                  <MoreVertIcon
                    aria-describedby={id}
                    style={{ fill: '#3C1053', cursor: 'pointer' }}
                    onClick={(e) => handlePopoverClick(e, service)}
                  />
                  <CustomPopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handlePopoverClose}
                    options={PopoverData}
                  />
                </div>
              )}
            </Stack>
          </Col>
        </Row>
      ))}
    </Row>
  );
};

export default SubServiceList;
