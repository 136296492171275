import LocalStorageManager from 'managers/LocalStorageManger';
import { postMethod } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const AUTH_LOGIN = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';

// Action Creators
export const fetchLoginRequest = () => ({
  type: AUTH_LOGIN,
});

export const fetchLoginSuccess = (users) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: users,
});

export const fetchLoginFailure = (error) => ({
  type: AUTH_LOGIN_FAILURE,
  payload: error,
});

// Thunk Function
export const authLoginCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchLoginRequest(data, external));
    try {
      const response = await postMethod(url.login, data);
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchLoginSuccess(response.data));

        LocalStorageManager.setItem('token', response?.data?.data?.key);
        LocalStorageManager.setItem('Role', response?.data?.data?.role);

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        if (response.status === 401) {
          ClearTokenRedirectLogin(external?.navigate);
        }
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchLoginFailure(error.message));
    }
  };
};
