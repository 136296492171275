import React, { useContext, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import Avtar from 'assets/images/Dashboard/avtar.png';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import CustomPopover from 'components/common/CustomPopover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { superAdminAppointmentsDetails } from 'routes/AppUrls';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const Appointments = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [viewData, setViewData] = useState('');

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setViewData(item);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const PopoverData = [
    {
      label: <FormattedMessage id="View Details" />,
      icon: (
        <VisibilityOutlinedIcon
          style={{ marginRight: '5px', marginTop: '-5px' }}
        />
      ),
      onClick: () => navigate(superAdminAppointmentsDetails(locale)),
    },
    {
      label: <FormattedMessage id="Delete" />,
      icon: (
        <DeleteOutlinedIcon style={{ marginRight: '5px', marginTop: '-5px' }} />
      ),
      // onClick: () => navigate(superAdminEditSubCategory(locale)),
    },
  ];

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Appointments" />
        </h2>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Customer" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Provider" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Service Location" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Booking ID" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Date & Time" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12} className="text-start">
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((key, index) => (
            <Row className="m-0 p-0 flex items-center listAppointment">
              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Avtar}
                    alt="icon"
                    style={{ height: '40px', width: '40px' }}
                  />
                  <div>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </div>
                </Stack>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Avtar}
                    alt="icon"
                    style={{ height: '40px', width: '40px' }}
                  />
                  <div>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Salon Name'}
                    </p>
                  </div>
                </Stack>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={Provider}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {'At Home'}
                  </p>
                </Stack>
                <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                  {'203A Spanish Tn Rd, Kingston'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                  {'1232654551'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                  {'09:30AM'}
                </p>
              </Col>
              <Col lg={2} md={6} sm={12} className="text-start">
                <div>
                  <MoreVertIcon
                    aria-describedby={id}
                    style={{ fill: '#3C1053', cursor: 'pointer' }}
                    onClick={(e) => handlePopoverClick(e)}
                  />
                  <CustomPopover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handlePopoverClose}
                    options={PopoverData}
                  />
                </div>
              </Col>
            </Row>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Appointments;
