import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const GET_ALL_PROVIDER_INFORMATION_DETAILS =
  'GET_ALL_PROVIDER_INFORMATION_DETAILS';
export const GET_ALL_PROVIDER_INFORMATION_DETAILS_SUCCESS =
  'GET_ALL_PROVIDER_INFORMATION_DETAILS_SUCCESS';
export const GET_ALL_PROVIDER_INFORMATION_DETAILS_FAILURE =
  'GET_ALL_PROVIDER_INFORMATION_DETAILS_FAILURE';

// Action Creators
export const fetchGetAllProviderInformationDetails = () => ({
  type: GET_ALL_PROVIDER_INFORMATION_DETAILS,
});

export const fetchGetAllProviderInformationDetailsSuccess = (data) => ({
  type: GET_ALL_PROVIDER_INFORMATION_DETAILS_SUCCESS,
  payload: data,
});

export const fetchGetAllProviderInformationDetailsFailure = (error) => ({
  type: GET_ALL_PROVIDER_INFORMATION_DETAILS_FAILURE,
  payload: error,
});

// Thunk Function
export const getAllProviderInformationDetailsCallAPI = (
  data,
  external = {}
) => {
  return async (dispatch) => {
    dispatch(fetchGetAllProviderInformationDetails(data, external));
    try {
      const response = await getMethodWithToken(
        url.getAllProviderInformationDetailsSuperAdmin +
          external?.id +
          `/?query=${data}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetAllProviderInformationDetailsSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        if (response.status === 401) {
          // ClearTokenRedirectLogin(external?.navigate);
        }
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetAllProviderInformationDetailsFailure(error.message));
    }
  };
};
