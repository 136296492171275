import React, { useContext, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { providerAdminCustomer } from 'routes/AppUrls';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import PhotoImage from 'assets/images/provider/image4.png';
import CaretUpIcon from 'assets/images/Category/CaretUp.png';
import CaretDownIcon from 'assets/images/Category/CaretDown.png';
import HouseIcon from 'assets/images/provider/HouseLine.png';
import StarIcon from '@mui/icons-material/Star';
import { getUserRole } from 'utils/general';
import Avtar from 'assets/images/Dashboard/avtar.png';

const ProviderCustomerDetails = () => {
  const navigate = useNavigate();
  const userRole = getUserRole();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [expandedId, setExpandedId] = useState(null);

  const toggleExpand = (id) => {
    setExpandedId((prev) => (prev === id ? null : id));
  };

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Customers" />,
      Link: providerAdminCustomer(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Customer Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const [appointmentHistory, setAppointmentHistory] = React.useState([
    {
      id: 1,
      bookingId: 1232654551,
      location: '203A Spanish Tn Rd, Kingston',
      date: '23 Nov 2019',
      time: '09:30AM',
      price: '130 SAR',
      subServices: [],
    },
    {
      id: 2,
      bookingId: 1232654551,
      location: '203A Spanish Tn Rd, Kingston',
      date: '23 Nov 2019',
      time: '09:30AM',
      price: '130 SAR',
      subServices: [
        { id: 1, booked: 'Haircut', rating: 4.9, time: 50, price: 160 },
        { id: 2, booked: 'Haircut', rating: 4.9, time: 50, price: 160 },
        { id: 3, booked: 'Haircut', rating: 4.9, time: 50, price: 160 },
      ],
    },
    {
      id: 3,
      bookingId: 1232654551,
      location: '203A Spanish Tn Rd, Kingston',
      date: '23 Nov 2019',
      time: '09:30AM',
      price: '130 SAR',
      subServices: [],
    },
    {
      id: 4,
      bookingId: 1232654551,
      location: '203A Spanish Tn Rd, Kingston',
      date: '23 Nov 2019',
      time: '09:30AM',
      price: '130 SAR',
      subServices: [],
    },
    {
      id: 5,
      bookingId: 1232654551,
      location: '203A Spanish Tn Rd, Kingston',
      date: '23 Nov 2019',
      time: '09:30AM',
      price: '130 SAR',
      subServices: [
        { id: 4, booked: 'Haircut', rating: 4.9, time: 50, price: 160 },
        { id: 5, booked: 'Haircut', rating: 4.9, time: 50, price: 160 },
        { id: 6, booked: 'Haircut', rating: 4.9, time: 50, price: 160 },
      ],
    },
  ]);

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <Col xs={12} md={6} className="mt-4">
            <div className="gap-3 flex">
              <img
                src={PhotoImage}
                alt={'icon'}
                style={{
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%',
                }}
              />
              <div className="flex-col d-flex justify-between">
                <div>
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Customer Name" />
                  </p>
                </div>
                <div>
                  <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                    ID:00001
                  </p>
                </div>
                <div className="flex gap-5">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={callIcon}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      9874456321
                    </p>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={mailIcon}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                      random123@mail
                    </p>
                  </Stack>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={6} className="mt-4 d-flex items-end">
            <div>
              <p className="p-0 m-0 fs-16 fw-400 fc-9A9A9A ff-Akkurat_Bold">
                <FormattedMessage id="Location" /> {':'}
              </p>
              <p className="p-0 m-0 fs-16 fw-400 fc-292D32 ff-Akkurat">
                <FormattedMessage id="203A Spanish Tn Rd, Kingston" />
              </p>
            </div>
          </Col>
        </Row>

        <h5 className="p-0 m-0 mt-4 fs-16 fw-400 fc-292D32 ff-Akkurat_Bold">
          <FormattedMessage id="Appointment History" />
        </h5>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Booking ID" />
            </p>
          </Col>
          {userRole === 'company-admin' && (
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Branch Name" />
              </p>
            </Col>
          )}
          {userRole !== 'specialist-admin' && (
            <Col
              lg={`${userRole !== 'specialist-admin' ? 2 : 3}`}
              md={6}
              sm={12}
            >
              <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                <FormattedMessage id="Specialist" />
              </p>
            </Col>
          )}
          <Col lg={`${userRole === 'specialist-admin' ? 3 : 2}`} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Service Location" />
            </p>
          </Col>
          <Col
            lg={`${userRole === 'specialist-admin' ? 3 : userRole === 'company-admin' ? 1 : 2}`}
            md={6}
            sm={12}
          >
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Date & Time" />
            </p>
          </Col>
          <Col lg={`${userRole === 'company-admin' ? 1 : 2}`} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Price" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="m-0 p-0 mt-3 flex items-center">
          {appointmentHistory.map((item, index) => (
            <Row
              key={item.id}
              className={`m-0 p-0 flex items-center ${appointmentHistory?.length === index + 1 ? 'py-4' : 'border-b py-4'} ${expandedId === item.id ? 'bc-F6F6F6' : 'bc-FFFFFF'}`}
            >
              <Col md={2} className="items-center">
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                  {item.bookingId}
                </p>
              </Col>

              {userRole === 'company-admin' && (
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {'Branch Name'}
                  </p>
                </Col>
              )}

              {userRole !== 'specialist-admin' && (
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={Avtar}
                      alt="icon"
                      style={{ height: '40px', width: '40px' }}
                    />
                    <div>
                      <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {'John Adams'}
                      </p>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                        {'00001'}
                      </p>
                    </div>
                  </Stack>
                </Col>
              )}

              <Col md={`${userRole === 'specialist-admin' ? 3 : 2}`}>
                <div className="flex-col items-center">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center "
                  >
                    <img
                      src={HouseIcon}
                      alt="icon"
                      style={{ height: '20px', width: '20px' }}
                    />
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                      {'At Home'}
                    </p>
                  </Stack>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {item.location}
                  </p>
                </div>
              </Col>

              <Col
                md={`${userRole === 'specialist-admin' ? 3 : userRole === 'company-admin' ? 1 : 2}`}
              >
                <div className="flex-col items-center">
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {item.date}
                  </p>
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                    {item.time}
                  </p>
                </div>
              </Col>

              <Col md={`${userRole === 'company-admin' ? 1 : 2}`}>
                <div className="flex-col items-center">
                  <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                    {item.price}
                  </p>
                </div>
              </Col>

              <Col md={2} className="text-start">
                {item.subServices?.length > 0 && (
                  <button
                    className="expand-btn text-lg"
                    onClick={() => toggleExpand(item.id)}
                    aria-label={`Toggle sub-services for ${item.id}`}
                  >
                    {expandedId === item.id ? (
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="Hide Services" />
                        </p>
                        <img
                          src={CaretUpIcon}
                          alt={'icon'}
                          style={{ height: '24px', width: '24px' }}
                        />
                      </Stack>
                    ) : (
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Services" />
                        </p>
                        <img
                          src={CaretDownIcon}
                          alt={'icon'}
                          style={{ height: '24px', width: '24px' }}
                        />
                      </Stack>
                    )}
                  </button>
                )}
              </Col>

              <Row className="m-0 p-0">
                {expandedId === item.id && item.subServices?.length > 0 && (
                  <>
                    <Row className="mt-4 listHeader m-0 p-0 flex items-center">
                      <Col lg={3} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                          <FormattedMessage id="Services Booked" />
                        </p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                          <FormattedMessage id="Rating" />
                        </p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                          <FormattedMessage id="Time" />
                        </p>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                          <FormattedMessage id="Price" />
                        </p>
                      </Col>
                    </Row>

                    {item.subServices?.length &&
                      item.subServices.map((sub, i) => (
                        <Row
                          key={sub.id}
                          className={`m-0 p-0 flex items-center ${item.subServices?.length === i + 1 ? 'pt-4' : 'border-b py-4'}`}
                        >
                          <Col lg={3} md={6} sm={12}>
                            <Stack
                              direction="horizontal"
                              gap={3}
                              className="items-center cursor-pointer"
                            >
                              <img
                                src={PhotoImage}
                                alt={'icon'}
                                style={{
                                  height: '48px',
                                  width: '48px',
                                  borderRadius: '50%',
                                }}
                              />
                              <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                                {sub?.booked}
                              </p>
                            </Stack>
                          </Col>

                          <Col lg={3} md={6} sm={12}>
                            <Stack
                              direction="horizontal"
                              gap={2}
                              className="items-center cursor-pointer"
                            >
                              <div className={'starfill'}>
                                <StarIcon fontSize="medium" />
                              </div>

                              <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                                {sub?.rating}
                              </p>
                            </Stack>
                          </Col>

                          <Col lg={3} md={6} sm={12}>
                            <p className="p-0 m-0 fs-16 fw-400 fc-292D32 ff-Akkurat">
                              {sub?.time} min
                            </p>
                          </Col>

                          <Col lg={3} md={6} sm={12}>
                            <p className="p-0 m-0 fs-16 fw-400 fc-292D32 ff-Akkurat_Bold">
                              {sub?.price} SAR
                            </p>
                          </Col>
                        </Row>
                      ))}
                  </>
                )}
              </Row>
            </Row>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default ProviderCustomerDetails;
