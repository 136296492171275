import { postMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const ADD_SALON_PROVIDER = 'ADD_SALON_PROVIDER';
export const ADD_SALON_PROVIDER_SUCCESS = 'ADD_SALON_PROVIDER_SUCCESS';
export const ADD_SALON_PROVIDER_FAILURE = 'ADD_SALON_PROVIDER_FAILURE';

// Action Creators
export const fetchAddSalonProvider = () => ({
  type: ADD_SALON_PROVIDER,
});

export const fetchAddSalonProviderSuccess = (data) => ({
  type: ADD_SALON_PROVIDER_SUCCESS,
  payload: data,
});

export const fetchAddSalonProviderFailure = (error) => ({
  type: ADD_SALON_PROVIDER_FAILURE,
  payload: error,
});

// Thunk Function
export const addSalonProviderCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchAddSalonProvider(data, external));
    try {
      const response = await postMethodWithToken(
        url.addSalonProviderSuperAdmin,
        data
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchAddSalonProviderSuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        if (response.status === 401) {
          // ClearTokenRedirectLogin(external?.navigate);
        }
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchAddSalonProviderFailure(error.message));
    }
  };
};
