import {
  ADD_SALON_PROVIDER,
  ADD_SALON_PROVIDER_FAILURE,
  ADD_SALON_PROVIDER_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const addSalonProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SALON_PROVIDER:
      return { ...state, loading: true };
    case ADD_SALON_PROVIDER_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case ADD_SALON_PROVIDER_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default addSalonProviderReducer;
