import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { LocaleContext } from 'routes/Router';

const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const PhoneLabel = styled.label`
  font-family: 'Akkurat_Bold';
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const PhoneInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CountryCodeInput = styled.div`
  margin-right: ${(props) => (props?.locale === 'en' ? '20px' : '')};
  margin-left: ${(props) => (props?.locale === 'ar' ? '20px' : '')};

  .form-control.custom-country-input {
    width: 150px !important ; /* Adjust width to fit the country code */
    border-radius: 8px;
    margin-left: 0px;
  }

  .react-tel-input {
    .form-control {
      border-radius: 0px;
      height: 50px;
      padding-left: 60px;
    }

    .flag-dropdown {
      border-radius: 0px;
      background-color: #fff;
      border: none;
    }

    .form-control:focus {
      outline: none;
      box-shadow: none;
      border-color: #000000;
    }

    .selected-flag {
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: 0px;
      border-radius: 0px;
    }

    .selected-flag .flag {
      margin-left: ${(props) => (props?.locale === 'en' ? '20px' : '')};
      margin-right: ${(props) => (props?.locale === 'ar' ? '30px' : '')};
    }
  }
`;

const PhoneNumberInputWrapper = styled.div`
  width: 100%;
  height: 50px;

  .input-with-suffix {
    position: relative;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      height: 50px;
      padding: 10px;
      padding-right: 40px;
      border: 1px solid #dddddd;
      border-radius: 0px;
      outline: none;
      box-shadow: none;

      &:focus {
        border-color: #000000;
      }

      &.invalid {
        border-color: #ee3232;
      }
    }

    .input-suffix {
      position: absolute;
      right: 15px;
      color: #292d32;
      font-family: 'Akkurat_Bold';
      font-size: 16px;
    }
  }
`;

const ErrorMessage = styled.p`
  font-size: 16px;
  color: #ee3232;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Akkurat';
  margin-top: 5px;
`;

const PhoneNumberInput = ({
  label,
  country,
  value,
  name,
  onCountryCodeChange,
  onPhoneNumberChange,
  phoneNumber,
  phoneNumberRef,
  placeholder,
  isPhoneNumberValid,
  isError,
  errorMessage,
  onlyCountries,
  suffixText,
}) => {
  const { locale } = useContext(LocaleContext);
  return (
    <PhoneNumberContainer>
      {label && <PhoneLabel>{label}</PhoneLabel>}
      <PhoneInputWrapper>
        <CountryCodeInput locale={locale}>
          <PhoneInput
            country={country}
            value={value}
            name={name}
            onChange={onCountryCodeChange}
            inputProps={{
              name: 'countryCode',
              required: true,
            }}
            placeholder="country"
            inputClass="custom-country-input"
            disableCountryCode
            disableDropdown={false}
            onlyCountries={onlyCountries}
          />
        </CountryCodeInput>

        <PhoneNumberInputWrapper>
          <div className="input-with-suffix">
            <input
              type="number"
              name={name}
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              ref={phoneNumberRef}
              placeholder={placeholder}
              className={!isPhoneNumberValid ? 'invalid' : ''}
            />
            {suffixText && <span className="input-suffix">{suffixText}</span>}
          </div>
        </PhoneNumberInputWrapper>
      </PhoneInputWrapper>

      {isError ? (
        <ErrorMessage>{isError}</ErrorMessage>
      ) : (
        !isPhoneNumberValid && (
          <ErrorMessage>
            {errorMessage || 'Invalid phone number for selected country code'}
          </ErrorMessage>
        )
      )}
    </PhoneNumberContainer>
  );
};

PhoneNumberInput.propTypes = {
  label: PropTypes.node,
  country: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onCountryCodeChange: PropTypes.func.isRequired,
  onPhoneNumberChange: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  phoneNumberRef: PropTypes.object,
  placeholder: PropTypes.string,
  isPhoneNumberValid: PropTypes.bool.isRequired,
  isError: PropTypes.object,
  errorMessage: PropTypes.string,
  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  suffixText: PropTypes.string,
};

PhoneNumberInput.defaultProps = {
  label: null,
  phoneNumberRef: null,
  placeholder: 'e.g: 8521364783',
  isError: null,
  errorMessage: null,
  onlyCountries: ['qa', 'us', 'in', 'gb'],
  suffixText: null,
};

export default PhoneNumberInput;
