import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col, Stack, Badge } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import ReusableTabs from 'components/common/Tabs';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';
import SwitchInput from 'components/common/Input/SwitchInput';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import visaCardIcon from 'assets/images/provider/visa.png';
import serviceImage from 'assets/images/provider/serviceImage.png';
import masterCardIcon from 'assets/images/provider/masterCard.png';
import americanCardIcon from 'assets/images/provider/americanExpress.png';
import cashCardIcon from 'assets/images/provider/cash.png';
import PhotoImage from 'assets/images/provider/image4.png';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import Avtar from 'assets/images/Dashboard/avtar.png';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EditIcon from 'assets/images/Tab/editIcon.png';
import crossIcon from 'assets/images/crossIcon.svg';
import { Box } from '@mui/system';
import CommonModal from 'components/common/Modal';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import ReusableButton from 'components/common/Button';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import { decryptData, encryptData } from 'utils/general';
import { getBranchDetailsProviderCallAPI } from 'store/ProviderAdmin/getBranchDetailsProvider/action';
import { useDispatch } from 'react-redux';

const BranchesDetails = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const { state } = useLocation();
  const [tab, setTab] = useState(1);

  const specialistImageFileInputRef = useRef();
  const [specialistImageFile, setSpecialistImageFile] = useState(null);
  const [checked, setChecked] = useState(false);
  const [Images, setImages] = useState([]);
  const ImagesRef = useRef();

  const [
    isBusinessInformationModalActive,
    setIsBusinessInformationModalActive,
  ] = useState(false);
  const [isInformationModalActive, setIsInformationModalActive] =
    useState(false);
  const [isImagesModalActive, setIsImagesModalActive] = useState(false);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isError, setIsError] = useState(null);
  const phoneNumberInputRef = useRef(null);

  const [formValue, setFormValue] = useState({
    owner: '',
    email: '',
    contactNumber: '',
    taxId: '',
    countryCodeContactNumber: '',
  });

  const tabData = [
    { label: intl.formatMessage({ id: 'About' }) },
    { label: intl.formatMessage({ id: 'Appointment History' }) },
    { label: intl.formatMessage({ id: 'Reviews' }) },
  ];

  useEffect(() => {
    getBranchDetailsApi();
  }, []);

  const getBranchDetailsApi = () => {
    dispatch(
      getBranchDetailsProviderCallAPI(
        {},
        {
          id: state,
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    console.log(res?.data?.data);
    // setbranchListData(res?.data?.data);
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  const handleSpecialistImageFileChange = (e) => {
    const file = e.target.files[0];
    setSpecialistImageFile(URL.createObjectURL(file));
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleSpecialistImageUpload = () => {
    specialistImageFileInputRef.current.click();
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  const ImageList = (images, maxVisible) => {
    const visibleImages = images.slice(0, maxVisible);
    const remainingCount = images.length - maxVisible;

    return (
      <div className="flex flex-wrap mt-3 gap-3 p-0">
        {visibleImages.map((image, index) => (
          <div className="image-item relative" key={index}>
            <img
              src={PhotoImage}
              alt={`Image ${index + 1}`}
              style={{ height: '124px', width: '124px' }}
            />
            {index === maxVisible - 1 && remainingCount > 0 && (
              <div className="overlay">
                <span>+{remainingCount}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const handleCloseBusinessInformationModel = () => {
    setIsBusinessInformationModalActive(false);
  };

  const handleCloseInformationModel = () => {
    setIsInformationModalActive(false);
  };

  const handleCloseImagesModel = () => {
    setIsImagesModalActive(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleCountryCodeChange = (value, name) => {
    setFormValue({ ...formValue, countryCodeContactNumber: value });
  };

  const handleImagesUpload = () => {
    ImagesRef.current.click();
  };

  const handleImagesChange = (e) => {
    const file = e.target.files[0];
    setImages([...Images, URL.createObjectURL(file)]);
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleDeleteImages = (data, index) => {
    const filterImages = Images?.filter((value) => value !== data);
    setImages(filterImages);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <input
            ref={specialistImageFileInputRef}
            type="file"
            accept=".png, .jpg, .jpeg"
            disabled={false}
            style={{ display: 'none' }}
            onChange={handleSpecialistImageFileChange}
          />

          <div className="flex items-end justify-end pe-4">
            <Stack
              direction="horizontal"
              gap={2}
              className="items-center cursor-pointer"
              onClick={() => setIsBusinessInformationModalActive(true)}
            >
              <img
                src={EditIcon}
                alt="icon"
                style={{ height: '24px', width: '24px' }}
              />
              <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Edit" />
              </p>
            </Stack>
          </div>

          <div className=" flex justify-between items-center">
            <Stack direction="horizontal" gap={4} className="items-center">
              <div className="AvtarImageIcon">
                {specialistImageFile && (
                  <img
                    src={specialistImageFile}
                    alt={'icon'}
                    style={{
                      height: '88px',
                      width: '88px',
                      borderRadius: '50%',
                    }}
                  />
                )}
                <img
                  src={ProfileIcon}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="profileIcon"
                  onClick={handleSpecialistImageUpload}
                />
              </div>
              <div>
                <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Branch Name" />
                </p>
                <p className="p-0 m-0 fs-16 fc-A4AAB2 fw-400 ff-Akkurat">
                  Owner: Christine Brooks
                </p>
              </div>
            </Stack>

            <div>
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                <FormattedMessage id="Location" />
              </p>
              <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                089 Kutch Green Apt. 448
              </p>
            </div>

            <div className="">
              <SwitchInput
                label={'Test User'}
                checked={checked}
                onChange={(e) => handleSwitchChange(e)}
                color="secondary"
              />
            </div>
          </div>

          <Col xs={12} md={3} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                <FormattedMessage id="Account Manager" />
              </p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {'Gilbert Johnson'}
            </p>
          </Col>

          <Col xs={12} md={3} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={callIcon}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                <FormattedMessage id="Phone number" />
              </p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {'+01 9874456321'}
            </p>
          </Col>

          <Col xs={12} md={3} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={mailIcon}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                <FormattedMessage id="Email" />
              </p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {'random123@mail'}
            </p>
          </Col>

          <Col xs={12} md={2} className="mt-3 text-center">
            <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
              <FormattedMessage id="Active since" />
              {':'}
            </p>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {'23 Nov 2019'}
            </p>
          </Col>
        </Row>

        <Row className="mt-3 pt-1">
          <Col xs={12} md={6}>
            <ReusableTabs
              tabs={tabData}
              defaultTab={1}
              currentTab={(val) => handleTabChange(val)}
            />
          </Col>
        </Row>

        {tab === 1 && (
          <>
            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <div className="flex justify-between p-0">
                <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Information" />
                </h5>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="items-center cursor-pointer"
                  onClick={() => setIsInformationModalActive(true)}
                >
                  <img
                    src={EditIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Edit" />
                  </p>
                </Stack>
              </div>
              <p className="p-0 m-0 fs-16 fc-A2A2A2 fw-400 ff-Akkurat mt-3">
                {
                  'Lorem ipsum dolor sit amet consectetur. Suspendisse amet mauris vitae etiam sit maecenas vel accumsan nec. Cras commodo egestas ridiculus velit et consectetur accumsan. Neque luctus tristique vestibulum eu. Sit sed quis et risus morbi. Volutpat ultrices tempor purus in purus etiam massa nullam. In commodo elit ac tincidunt. Fermentum diam magna pellentesque penatibus vel nec integer mauri.'
                }
              </p>
            </Row>

            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <div className="flex justify-between p-0">
                <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Payment Methods" />
                </h5>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="items-center cursor-pointer"
                >
                  <img
                    src={EditIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Edit" />
                  </p>
                </Stack>
              </div>
              <div className="flex flex-wrap mt-3 gap-3  p-0">
                <img
                  src={visaCardIcon}
                  alt="icon"
                  style={{ height: '40px', width: '73px' }}
                />
                <img
                  src={masterCardIcon}
                  alt="icon"
                  style={{ height: '40px', width: '73px' }}
                />
                <img
                  src={americanCardIcon}
                  alt="icon"
                  style={{ height: '40px', width: '73px' }}
                />
                <img
                  src={cashCardIcon}
                  alt="icon"
                  style={{ height: '40px', width: '73px' }}
                />
              </div>
            </Row>

            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <div className="flex justify-between p-0">
                <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Ambience" />
                </h5>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="items-center cursor-pointer"
                >
                  <img
                    src={EditIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Edit" />
                  </p>
                </Stack>
              </div>

              <div className="flex flex-wrap mt-2 gap-3  p-0">
                {[1, 2, 3, 4].map((k, i) => (
                  <Badge
                    pill
                    className={'d-flex items-center text-center ambiencePill'}
                  >
                    <FormattedMessage id="Cozy" />
                  </Badge>
                ))}
              </div>
            </Row>

            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <div className="flex justify-between p-0">
                <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Photos" />
                </h5>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="items-center cursor-pointer"
                  onClick={() => setIsImagesModalActive(true)}
                >
                  <img
                    src={EditIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Edit" />
                  </p>
                </Stack>
              </div>
              <div className="p-0">
                {ImageList(
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                  11
                )}
              </div>
            </Row>
          </>
        )}

        {tab === 2 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Service Type" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Customer" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Specialist" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Service Location" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Booking ID" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Date & Time" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5, 6, 7].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <img
                      src={serviceImage}
                      alt="icon"
                      style={{ height: '48px', width: '48px' }}
                    />
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                    >
                      <img
                        src={Avtar}
                        alt="icon"
                        style={{ height: '40px', width: '40px' }}
                      />
                      <div>
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {'Christine Brooks'}
                        </p>
                        <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                          {'00001'}
                        </p>
                      </div>
                    </Stack>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                    >
                      <img
                        src={Avtar}
                        alt="icon"
                        style={{ height: '40px', width: '40px' }}
                      />
                      <div>
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {'John Adams'}
                        </p>
                        <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                          {'00001'}
                        </p>
                      </div>
                    </Stack>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                    >
                      <img
                        src={Provider}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {'At Home'}
                      </p>
                    </Stack>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'1232654551'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'09:30AM'}
                    </p>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}

        {tab === 3 && (
          <>
            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <Col xs={12} md={12}>
                <h5 className="p-0 m-0 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Reviews" />
                </h5>
              </Col>

              <Col xs={12} md={3} className="mt-3">
                <p className="p-0 m-0 fs-32 fw-400 fc-292D32 ff-Akkurat_Bold">
                  {'4.6'}
                </p>
                <Stack
                  direction="horizontal"
                  spacing={1}
                  className="mt-1 items-center"
                >
                  {[1, 2, 3, 4, 5].map((star) => (
                    <div
                      key={star}
                      className={star <= 4 ? 'starfill' : 'starUnfill'}
                    >
                      {star <= 4 ? (
                        <StarIcon fontSize="medium" />
                      ) : (
                        <StarBorderIcon fontSize="medium" />
                      )}
                    </div>
                  ))}
                </Stack>
              </Col>

              <Col xs={12} md={7} className="">
                {[1, 2, 3].map((value, index) => (
                  <Col
                    xs={12}
                    md={12}
                    className="mt-3 border-b bColor-E3E3E3 pb-2"
                  >
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center"
                    >
                      <img
                        src={PhotoImage}
                        alt={'icon'}
                        style={{
                          height: '88px',
                          width: '88px',
                          borderRadius: '50%',
                        }}
                      />
                      <div>
                        <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                          John Doe
                        </p>
                        <p className="p-0 m-0 mt-2 fs-14 fc-A2A2A2 fw-400 ff-Akkurat">
                          22nd March, 2024
                        </p>
                      </div>
                    </Stack>

                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="mt-3 items-center"
                    >
                      <>
                        <div className="flex">
                          {[1, 2, 3, 4, 5].map((star) => (
                            <div
                              key={star}
                              className={star <= 4 ? 'starfill' : 'starUnfill'}
                            >
                              {star <= 4 ? (
                                <StarIcon fontSize="medium" />
                              ) : (
                                <StarBorderIcon fontSize="medium" />
                              )}
                            </div>
                          ))}
                        </div>
                        <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {'4'}
                        </p>
                      </>
                    </Stack>

                    <div className="mt-2">
                      <p className="p-0 m-0 fs-16 fc-A2A2A2 fw-400 ff-Akkurat">
                        {
                          'I was initially apprehensive, having no prior design experience. But the instructor, John Doe, did an amazing job of breaking down complex concepts into easily digestible modules. The video lectures were engaging, and the real-world examples really helped solidify my understanding.'
                        }
                      </p>
                    </div>
                  </Col>
                ))}
              </Col>
            </Row>
          </>
        )}

        {/* Edit Business Information Modal  */}
        <CommonModal
          padding={'32px 48px'}
          width={'736px'}
          modalActive={isBusinessInformationModalActive}
          handleClose={() => handleCloseBusinessInformationModel()}
          innerContent={
            <Box>
              <Row>
                <Col xs={12} md={12}>
                  <div className="flex justify-between items-center">
                    <h2 className="p-0 m-0 fs-24 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {<FormattedMessage id="Edit Business Information" />}
                    </h2>
                    <img
                      src={crossIcon}
                      alt={'icon'}
                      style={{
                        cursor: 'pointer',
                        height: '32px',
                        width: '32px',
                      }}
                      onClick={() => handleCloseBusinessInformationModel()}
                    />
                  </div>
                </Col>

                <Col xs={12} md={6}>
                  <LabelWithInput
                    label={<FormattedMessage id="Owner" />}
                    placeholder={intl.formatMessage({
                      id: 'owner',
                    })}
                    name="owner"
                    type="text"
                    value={formValue?.owner}
                    onChange={(e) => handleChange(e)}
                    // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <LabelWithInput
                    label={<FormattedMessage id="Email" />}
                    placeholder={intl.formatMessage({ id: 'Email' })}
                    name="email"
                    type="text"
                    value={formValue?.email}
                    onChange={handleInputChange}
                    // errorText={isError && isError.email.error && isError.email.errorMessage}
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label="Contact Number"
                    country={formValue?.countryCodeContactNumber}
                    value={formValue?.countryCodeContactNumber}
                    name={'contactNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'contactNumber')
                    }
                    onPhoneNumberChange={handleChange}
                    phoneNumber={formValue.contactNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={isError}
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Tax ID" />}
                    placeholder={intl.formatMessage({
                      id: 'Tax ID',
                    })}
                    name="taxId"
                    type="text"
                    value={formValue?.taxId}
                    onChange={(e) => handleChange(e)}
                    // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
                  />
                </Col>
              </Row>

              <Row className="mt-4 items-end">
                <div className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => handleCloseBusinessInformationModel()}
                    >
                      <FormattedMessage id="Cancel" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                    >
                      <FormattedMessage id="Save Changes" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>
            </Box>
          }
        />
        {/* Edit Business Information Modal  */}

        {/* Edit Information Modal  */}
        <CommonModal
          padding={'32px 48px'}
          width={'736px'}
          modalActive={isInformationModalActive}
          handleClose={() => handleCloseInformationModel()}
          innerContent={
            <Box>
              <Row>
                <Col xs={12} md={12}>
                  <div className="flex justify-between items-center">
                    <h2 className="p-0 m-0 fs-24 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {<FormattedMessage id="Edit Information" />}
                    </h2>
                    <img
                      src={crossIcon}
                      alt={'icon'}
                      style={{
                        cursor: 'pointer',
                        height: '32px',
                        width: '32px',
                      }}
                      onClick={() => handleCloseInformationModel()}
                    />
                  </div>
                </Col>

                <Col xs={12} md={12}>
                  <LabelWithInput
                    marginLabelTop={'0'}
                    placeholder={intl.formatMessage({
                      id: 'owner',
                    })}
                    height="120"
                    multiline
                    name="owner"
                    type="text"
                    value={formValue?.owner}
                    onChange={(e) => handleChange(e)}
                    // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
                  />
                </Col>
              </Row>

              <Row className="mt-4 items-end">
                <div className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => handleCloseInformationModel()}
                    >
                      <FormattedMessage id="Cancel" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                    >
                      <FormattedMessage id="Save Changes" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>
            </Box>
          }
        />
        {/* Edit Information Modal  */}

        {/* Edit Images Modal  */}
        <CommonModal
          padding={'32px 48px'}
          width={'800px'}
          modalActive={isImagesModalActive}
          handleClose={() => handleCloseImagesModel()}
          innerContent={
            <Box>
              <Row>
                <Col xs={12} md={12}>
                  <div className="flex justify-between items-center">
                    <h2 className="p-0 m-0 fs-24 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {<FormattedMessage id="Edit Images" />}
                    </h2>
                    <img
                      src={crossIcon}
                      alt={'icon'}
                      style={{
                        cursor: 'pointer',
                        height: '32px',
                        width: '32px',
                      }}
                      onClick={() => handleCloseImagesModel()}
                    />
                  </div>
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <div className="flex justify-end items-center">
                    {Images?.length > 0 && (
                      <ReusableButton
                        fullWidth={false}
                        variant="outlined"
                        color="#292D32"
                        fontSize="16px"
                        fontWeight="400"
                        padding="12px 25px"
                        height="44px"
                        bgColor="transparent"
                        fontFamily="Akkurat"
                        onClick={handleImagesUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                          className="me-2"
                        />
                        <FormattedMessage id="Add Images" />
                      </ReusableButton>
                    )}
                  </div>

                  <input
                    ref={ImagesRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    disabled={false}
                    style={{ display: 'none' }}
                    onChange={handleImagesChange}
                  />

                  {Images?.length <= 0 ? (
                    <div className="mt-2 bussinessImages flex justify-center align-center">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={handleImagesUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                          <FormattedMessage id="AddImageEn" />
                        </p>
                      </Stack>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-2 mt-3">
                      {Images.map((key, index) => (
                        <div className="relative">
                          <img
                            src={key}
                            alt="image"
                            style={{ height: '122px', width: '212px' }}
                          />
                          <img
                            src={crossIcon}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            className="absolute top-2 right-2 cursor-pointer"
                            onClick={() => handleDeleteImages(key, index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>

              <Row className="mt-4 items-end">
                <div className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => handleCloseImagesModel()}
                    >
                      <FormattedMessage id="Cancel" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                    >
                      <FormattedMessage id="Save Changes" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>
            </Box>
          }
        />
        {/* Edit Images Modal  */}
      </Col>
    </Row>
  );
};

export default BranchesDetails;
