import {
  GET_ALL_PROVIDER_INFORMATION_DETAILS,
  GET_ALL_PROVIDER_INFORMATION_DETAILS_FAILURE,
  GET_ALL_PROVIDER_INFORMATION_DETAILS_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const getAllProviderInformationDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_ALL_PROVIDER_INFORMATION_DETAILS:
      return { ...state, loading: true };
    case GET_ALL_PROVIDER_INFORMATION_DETAILS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case GET_ALL_PROVIDER_INFORMATION_DETAILS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default getAllProviderInformationDetailsReducer;
