import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import ServiceLogo from 'assets/images/Dashboard/Logo.png';
import ReusableButton from 'components/common/Button';
import addIcon from 'assets/images/Dashboard/add.png';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import EditIcon from 'assets/images/Tab/editIcon.png';
import {
  superAdminAddCategory,
  superAdminAddService,
  superAdminAddSubCategory,
  superAdminEditCategory,
  superAdminEditSubCategory,
} from 'routes/AppUrls';
import ScrollableTabs from 'components/common/SrollableTabs';
import ServiceList from './ServiceList';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryCallAPI } from 'store/SuperAdmin/getCategory/action';
import { getSubCategoryCallAPI } from 'store/SuperAdmin/getSubCategory/action';
import { deleteCategoryCallAPI } from 'store/SuperAdmin/deleteCategory/action';
import { deleteSubCategoryCallAPI } from 'store/SuperAdmin/deleteSubCategory/action';
import ToastNotification from 'components/common/Toast';
import {
  basicDetailsCallApi,
  BASIC_DETAILS,
  SELECTED_TAB,
  SERVICE_DETAILS,
  SUB_CATEGORY_DETAILS,
} from 'store/SuperAdmin/basicDetails/action';
import { deleteServiceCallAPI } from 'store/SuperAdmin/deleteService/action';

const Categories = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [tabData, setTabData] = useState(null);
  const [conf, setConf] = ToastNotification();
  const [subCategoryList, setSubCategoryList] = useState([]);

  const getCategoryData = useSelector((state) => state?.getCategoryReducer);
  const getselectedTab = useSelector(
    (state) => state?.basicDetailsReducer?.selectedTab
  );
  const selectedCategory = useSelector(
    (state) => state?.basicDetailsReducer?.editCategory
  );
  const [selectedTab, setSelectedTab] = useState(getselectedTab);

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    const selectedCategory =
      getCategoryData.data.data?.length > 0 &&
      getCategoryData.data.data[selectedTab];
    setTabData(selectedCategory);
    dispatch(basicDetailsCallApi(BASIC_DETAILS, selectedCategory));
    dispatch(basicDetailsCallApi(SELECTED_TAB, selectedTab));
    getSubCategoryList(selectedCategory?.slug);
  }, [selectedTab, getCategoryData]);

  const getCategoryList = () => {
    dispatch(
      getCategoryCallAPI(
        {},
        {
          navigate: navigate,
        }
      )
    );
  };

  const getSubCategoryList = (slug) => {
    dispatch(
      getSubCategoryCallAPI(
        {},
        {
          slug: slug,
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleSubCategoryData(res),
        }
      )
    );
  };

  const handleSubCategoryData = (res) => {
    setSubCategoryList(res?.data?.data);
  };

  const handleAddService = (data) => {
    dispatch(basicDetailsCallApi(SERVICE_DETAILS, data));
    navigate(superAdminAddService(locale));
  };

  const handleEditService = (data) => {
    dispatch(basicDetailsCallApi(SUB_CATEGORY_DETAILS, data));
    navigate(superAdminEditSubCategory(locale));
  };

  const handleDeleteService = (data) => {
    dispatch(
      deleteSubCategoryCallAPI(
        {},
        {
          id: data?.id,
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleRedirectSubCategory(res, data),
        }
      )
    );
  };

  const handleDeleteSubService = (data) => {
    dispatch(
      deleteServiceCallAPI(
        {},
        {
          id: data?.id,
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleRedirectService(res, selectedCategory),
        }
      )
    );
  };

  const handleRedirectService = (res, data) => {
    setConf({
      msg: res?.data?.message,
      variant: 'error',
    });
    getSubCategoryList(data?.slug);
  };

  const handleRedirectSubCategory = (res, data) => {
    setConf({
      msg: res?.data?.message,
      variant: 'error',
    });
    getSubCategoryList(data?.category?.slug);
  };

  const handleDeleteCategory = () => {
    dispatch(
      deleteCategoryCallAPI(
        {},
        {
          id: tabData?.id,
          navigate: navigate,
          run: true,
          handleFunction: (res) => handleRedirect(res),
        }
      )
    );
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'error',
    });
    getCategoryList();
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <FormattedMessage id="Services & Categories" />
            </h2>
            <img
              src={ServiceLogo}
              alt={'logo'}
              style={{ height: '64px', width: '96px' }}
            />
          </div>

          {getCategoryData?.data?.data?.length > 0 ? (
            <>
              <Row className="mt-4 m-0 p-0">
                <div className="flex justify-between items-center">
                  <h2 className="fc-292D32 fs-24 fw-400 ff-Akkurat_Bold m-0">
                    <FormattedMessage id="Categories" />
                  </h2>

                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                      onClick={() => navigate(superAdminAddCategory(locale))}
                    >
                      <img
                        src={addIcon}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                        className="me-2"
                      />
                      <FormattedMessage id="Add Category" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#000000"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      brColor="#DDDDDD"
                      fontFamily="Akkurat"
                      onClick={() => handleDeleteCategory()}
                    >
                      <FormattedMessage id="Delete" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>

              <Row className="m-0 p-0">
                <Col md={12}>
                  <ScrollableTabs
                    tabs={getCategoryData.data.data.map((items, index) => ({
                      label: items?.name,
                      icon: items?.image,
                    }))}
                    onTabChange={handleTabChange}
                    initialTab={selectedTab}
                  />
                </Col>
              </Row>
              {getCategoryData.data.data.map(
                (items, index) =>
                  tabData?.id === items?.id && (
                    <>
                      <Row className="m-0 p-0 mt-4">
                        <div className="flex justify-between items-center">
                          <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
                            {items?.name}
                          </h2>
                          <Stack
                            direction="horizontal"
                            gap={4}
                            className="items-center "
                          >
                            <ReusableButton
                              fullWidth={false}
                              variant="outlined"
                              color="#000000"
                              fontSize="16px"
                              fontWeight="400"
                              padding="12px 25px"
                              height="44px"
                              bgColor="transparent"
                              brColor="#DDDDDD"
                              fontFamily="Akkurat"
                              onClick={() =>
                                navigate(superAdminAddSubCategory(locale))
                              }
                            >
                              <img
                                src={AddBlackColor}
                                alt="icon"
                                style={{ height: '24px', width: '24px' }}
                                className="me-2"
                              />
                              <FormattedMessage id="Add Sub-Category" />
                            </ReusableButton>

                            <ReusableButton
                              fullWidth={false}
                              variant="outlined"
                              color="#000000"
                              fontSize="16px"
                              fontWeight="400"
                              padding="12px 25px"
                              height="44px"
                              bgColor="transparent"
                              brColor="#DDDDDD"
                              fontFamily="Akkurat"
                              onClick={() =>
                                navigate(superAdminEditCategory(locale))
                              }
                            >
                              <img
                                src={EditIcon}
                                alt="icon"
                                style={{ height: '24px', width: '24px' }}
                                className="me-2"
                              />
                              <FormattedMessage id="Edit category" />
                            </ReusableButton>
                          </Stack>
                        </div>
                      </Row>

                      {subCategoryList?.length > 0 && (
                        <Row className="m-0 p-0 mt-2">
                          <ServiceList
                            services={subCategoryList}
                            onEdit={(data) => handleEditService(data)}
                            onDelete={(data) => handleDeleteService(data)}
                            onDeleteSubService={(data) =>
                              handleDeleteSubService(data)
                            }
                            onAddService={(data) => handleAddService(data)}
                          />
                        </Row>
                      )}
                    </>
                  )
              )}
            </>
          ) : (
            <Row className="mt-5 pt-5 text-center">
              <p className="fc-292D32 fs-16 fw-400 ff-Akkurat m-0">
                <FormattedMessage id="No category added" />
              </p>
              <div className="mt-4 mb-5">
                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                  onClick={() => navigate(superAdminAddCategory(locale))}
                >
                  <img
                    src={addIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="me-2"
                  />
                  <FormattedMessage id="Add Category" />
                </ReusableButton>
              </div>
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Categories;
