import { deleteMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin } from 'utils/general';

// Action Types
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

// Action Creators
export const fetchDeleteCategory = () => ({
  type: DELETE_CATEGORY,
});

export const fetchDeleteCategorySuccess = (data) => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchDeleteCategoryFailure = (error) => ({
  type: DELETE_CATEGORY_FAILURE,
  payload: error,
});

// Thunk Function
export const deleteCategoryCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchDeleteCategory(data, external));
    try {
      const response = await deleteMethodWithToken(
        url.deleteCategoryListSuperAdmin + external?.id + '/' + 'delete' + '/'
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchDeleteCategorySuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        if (response.status === 401) {
          // ClearTokenRedirectLogin(external?.navigate);
        }
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchDeleteCategoryFailure(error.message));
    }
  };
};
