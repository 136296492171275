import { deleteMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';

// Action Types
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY_SUCCESS = 'DELETE_SUB_CATEGORY_SUCCESS';
export const DELETE_SUB_CATEGORY_FAILURE = 'DELETE_SUB_CATEGORY_FAILURE';

// Action Creators
export const fetchDeleteSubCategory = () => ({
  type: DELETE_SUB_CATEGORY,
});

export const fetchDeleteSubCategorySuccess = (data) => ({
  type: DELETE_SUB_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchDeleteSubCategoryFailure = (error) => ({
  type: DELETE_SUB_CATEGORY_FAILURE,
  payload: error,
});

// Thunk Function
export const deleteSubCategoryCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchDeleteSubCategory(data, external));
    try {
      const userRole = getUserRole();
      const response = await deleteMethodWithToken(
        userRole === 'super-admin'
          ? url.deleteSubCategoryListSuperAdmin +
              external?.id +
              '/' +
              'delete' +
              '/'
          : url.deleteSubCategoryServiceProviderAdmin + external?.id + '/'
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchDeleteSubCategorySuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        if (response.status === 401) {
          // ClearTokenRedirectLogin(external?.navigate);
        }
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchDeleteSubCategoryFailure(error.message));
    }
  };
};
