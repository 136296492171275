import React, { useContext, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import ServiceLogo from 'assets/images/Dashboard/Logo.png';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import ReusableButton from 'components/common/Button';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { providerAdminCategory } from 'routes/AppUrls';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import leftArrow from 'assets/images/Dashboard/arrow-left.png';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SwitchInput from 'components/common/Input/SwitchInput';
import { useDispatch, useSelector } from 'react-redux';
import CheckValidation from 'utils/Validation';
import ToastNotification from 'components/common/Toast';
import {
  convertToRaw,
  convertFromHTML,
  ContentState,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { updateSubCategoryCallAPI } from 'store/SuperAdmin/updateSubCategory/action';
import CircularProgress from '@mui/material/CircularProgress';

const ProviderEditSubCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [description, setDescription] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [conf, setConf] = ToastNotification();

  const editSubCategoryLoader = useSelector(
    (state) => state?.updategitSubCategoryReducer?.loading
  );

  const editCategoryData = useSelector(
    (state) => state?.basicDetailsReducer?.subCategoryData
  );

  const CategoryData = useSelector(
    (state) => state?.basicDetailsReducer?.editCategory
  );

  const [formValue, setFormValue] = useState({
    subCategoryNameEn: editCategoryData?.product_name_en || '',
    price: editCategoryData?.price || '',
    timeRequired: editCategoryData?.time_required_in_mints || '',
    productsUsedEn: editCategoryData?.material_used_en || '',
    benefitsEn: editCategoryData?.benefits_en || '',
    procedureEn: editCategoryData?.procedure_en || '',
    precautionsEn: editCategoryData?.precautions_en || '',
    thingsToKnowEn: editCategoryData?.things_to_know_en || '',
    afterCareTipsEn: editCategoryData?.after_care_tips_en || '',
    subCategoryNameAr: editCategoryData?.product_name_ar || '',
    productsUsedAr: editCategoryData?.material_used_ar || '',
    benefitsAr: editCategoryData?.benefits_ar || '',
    procedureAr: editCategoryData?.procedure_ar || '',
    precautionsAr: editCategoryData?.precautions_ar || '',
    thingsToKnowAr: editCategoryData?.things_to_know_ar || '',
    afterCareTipsAr: editCategoryData?.after_care_tips_ar || '',
  });

  const initializeEditorState = (htmlContent) => {
    if (htmlContent) {
      const blocksFromHTML = convertFromHTML(htmlContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const [htmlValues, setHtmlValues] = useState({
    productsUsedEn: initializeEditorState(editCategoryData?.material_used_en),
    benefitsEn: initializeEditorState(editCategoryData?.benefits_en),
    procedureEn: initializeEditorState(editCategoryData?.procedure_en),
    precautionsEn: initializeEditorState(editCategoryData?.precautions_en),
    thingsToKnowEn: initializeEditorState(editCategoryData?.things_to_know_en),
    afterCareTipsEn: initializeEditorState(
      editCategoryData?.after_care_tips_en
    ),
    productsUsedAr: initializeEditorState(editCategoryData?.material_used_ar),
    benefitsAr: initializeEditorState(editCategoryData?.benefits_ar),
    procedureAr: initializeEditorState(editCategoryData?.procedure_ar),
    precautionsAr: initializeEditorState(editCategoryData?.precautions_ar),
    thingsToKnowAr: initializeEditorState(editCategoryData?.things_to_know_ar),
    afterCareTipsAr: initializeEditorState(
      editCategoryData?.after_care_tips_ar
    ),
  });

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Service Categories" />,
      Link: providerAdminCategory(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Edit Sub-Category" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const onEditorStateChange = (editorState, name) => {
    const htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setHtmlValues((prev) => ({
      ...prev,
      [name]: editorState,
    }));
    setFormValue((prev) => ({
      ...prev,
      [name]: htmlContent,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  const handleSubmit = () => {
    const { subCategoryNameEn, subCategoryNameAr } = formValue;
    const error = CheckValidation({
      subCategoryNameEn,
      subCategoryNameAr,
    });
    setIsError(error);
    if (error.submit) {
      const body = {
        master_category_id: CategoryData?.id,
        currency: 'rupees',
        name_en: formValue?.subCategoryNameEn || '',
        name_ar: formValue?.subCategoryNameAr || '',
        title_ar: formValue?.subCategoryNameAr || '',
        price: formValue?.price || '',
        time_required_in_mints: formValue?.timeRequired || '',
        material_used_en: formValue?.productsUsedEn || '',
        material_used_ar: formValue?.productsUsedAr || '',
        benefits_en: formValue?.benefitsEn || '',
        benefits_ar: formValue?.benefitsAr || '',
        procedure_en: formValue?.procedureEn || '',
        procedure_ar: formValue?.procedureAr || '',
        precautions_en: formValue?.precautionsEn || '',
        precautions_ar: formValue?.precautionsAr || '',
        things_to_know_en: formValue?.thingsToKnowEn || '',
        things_to_know_ar: formValue?.thingsToKnowAr || '',
        after_care_tips_en: formValue?.afterCareTipsEn || '',
        after_care_tips_ar: formValue?.afterCareTipsAr || '',
      };

      dispatch(
        updateSubCategoryCallAPI(body, {
          id: editCategoryData?.id,
          run: true,
          handleFunction: (res) => handleRedirect(res),
          navigate: navigate,
        })
      );
    }
  };

  const handleRedirect = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    setTimeout(() => {
      navigate(providerAdminCategory(locale));
    }, 1000);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={leftArrow}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(providerAdminCategory(locale))}
                />
                <p className="p-0 m-0">
                  <FormattedMessage id="Edit Sub-Category" />
                </p>
              </Stack>
            </h2>
            <img
              src={ServiceLogo}
              alt={'logo'}
              style={{ height: '64px', width: '96px' }}
            />
          </div>

          <>
            <Row className="m-0 p-0">
              <div className="grid justify-end text-end">
                <SwitchInput
                  label={'En/Ar'}
                  checked={checked}
                  onChange={(e) => handleSwitchChange(e)}
                  color="secondary"
                />
              </div>
            </Row>

            {!checked ? (
              <>
                <Row className="m-0 p-0">
                  <Col xs={12} md={6} className="p-0">
                    <Row className="m-0 p-0">
                      <Col xs={12} md={12}>
                        <LabelWithInput
                          label={<FormattedMessage id="SubCategoryNameEn" />}
                          placeholder={intl.formatMessage({
                            id: 'SubCategoryNameEn',
                          })}
                          name="subCategoryNameEn"
                          type="text"
                          value={formValue?.subCategoryNameEn}
                          onChange={handleInputChange}
                          errorText={
                            isError &&
                            isError.subCategoryNameEn.error &&
                            isError.subCategoryNameEn.errorMessage
                          }
                        />
                      </Col>

                      <Col xs={12} md={12} className="p-0">
                        <Row className="m-0 p-0">
                          <Col xs={12} md={6}>
                            <LabelWithInput
                              label={<FormattedMessage id="Price" />}
                              placeholder={intl.formatMessage({
                                id: 'Price',
                              })}
                              name="price"
                              type="text"
                              value={formValue?.price}
                              onChange={handleInputChange}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <LabelWithInput
                              label={<FormattedMessage id="TimeRequired" />}
                              placeholder={intl.formatMessage({
                                id: 'TimeRequired',
                              })}
                              name="timeRequired"
                              type="text"
                              value={formValue?.timeRequired}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="m-0 p-0">
                  <Col xs={12} md={3} className="mt-3 pt-15">
                    <p className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="DescriptionEn" />
                    </p>
                    {!description && (
                      <div
                        className="mt-2 imageBorderWithoutDashed flex justify-center align-center cursor-pointer"
                        onClick={() => setDescription(!description)}
                      >
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <img
                            src={AddBlackColor}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                          />
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                            <FormattedMessage id="EditDescriptionEn" />
                          </p>
                        </Stack>
                      </div>
                    )}
                  </Col>
                </Row>

                {description && (
                  <>
                    <Col xs={12} md={12} className="mt-2">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ProductsusedEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.productsUsedEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'productsUsedEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="BenefitsEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.benefitsEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'benefitsEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ProcedureEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.procedureEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'procedureEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="PrecautionsEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.precautionsEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'precautionsEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ThingstoknowEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.thingsToKnowEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'thingsToKnowEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="AftercaretipsEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.afterCareTipsEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'afterCareTipsEn')
                          }
                        />
                      </div>
                    </Col>
                  </>
                )}
              </>
            ) : (
              <>
                <Row className="m-0 p-0">
                  <Col xs={12} md={6} className="p-0">
                    <Row className="m-0 p-0">
                      <Col xs={12} md={12}>
                        <LabelWithInput
                          label={<FormattedMessage id="SubCategoryNameAr" />}
                          placeholder={intl.formatMessage({
                            id: 'SubCategoryNameAr',
                          })}
                          name="subCategoryNameAr"
                          type="text"
                          value={formValue?.subCategoryNameAr}
                          onChange={handleInputChange}
                          errorText={
                            isError &&
                            isError.subCategoryNameAr.error &&
                            isError.subCategoryNameAr.errorMessage
                          }
                        />
                      </Col>

                      <Col xs={12} md={12} className="p-0">
                        <Row className="m-0 p-0">
                          <Col xs={12} md={6}>
                            <LabelWithInput
                              label={<FormattedMessage id="Price" />}
                              placeholder={intl.formatMessage({
                                id: 'Price',
                              })}
                              name="price"
                              type="text"
                              value={formValue?.price}
                              onChange={handleInputChange}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <LabelWithInput
                              label={<FormattedMessage id="TimeRequired" />}
                              placeholder={intl.formatMessage({
                                id: 'TimeRequired',
                              })}
                              name="timeRequired"
                              type="text"
                              value={formValue?.timeRequired}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="m-0 p-0">
                  <Col xs={12} md={3} className="mt-3 pt-15">
                    <p className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="DescriptionAr" />
                    </p>
                    {!description && (
                      <div
                        className="mt-2 imageBorderWithoutDashed flex justify-center align-center cursor-pointer"
                        onClick={() => setDescription(!description)}
                      >
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <img
                            src={AddBlackColor}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                          />
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                            <FormattedMessage id="EditDescriptionAr" />
                          </p>
                        </Stack>
                      </div>
                    )}
                  </Col>
                </Row>

                {description && (
                  <>
                    <Col xs={12} md={12} className="mt-2">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ProductsusedAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.productsUsedAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'productsUsedAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="BenefitsAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.benefitsAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'benefitsAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ProcedureAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.procedureAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'procedureAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="PrecautionsAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.precautionsAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'precautionsAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ThingstoknowAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.thingsToKnowAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'thingsToKnowAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="AftercaretipsAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={htmlValues?.afterCareTipsAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'afterCareTipsAr')
                          }
                        />
                      </div>
                    </Col>
                  </>
                )}
              </>
            )}

            <Row className="mt-4 items-end m-0 p-0">
              <div className="flex justify-end">
                <Stack direction="horizontal" gap={3} className="items-center">
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#292D32"
                    fontSize="16px"
                    fontWeight="400"
                    padding="12px 25px"
                    height="44px"
                    bgColor="transparent"
                    fontFamily="Akkurat"
                    onClick={() => navigate(providerAdminCategory(locale))}
                  >
                    <FormattedMessage id="Cancel" />
                  </ReusableButton>

                  <ReusableButton
                    fullWidth={false}
                    variant="contained"
                    color="#F5F5F5"
                    fontSize="16px"
                    fontWeight="400"
                    padding="12px 25px"
                    height="44px"
                    bgColor="#292D32"
                    fontFamily="Akkurat"
                    disabled={
                      formValue?.subCategoryNameEn !== '' &&
                      formValue?.subCategoryNameAr !== ''
                        ? false
                        : true
                    }
                    endIcon={
                      editSubCategoryLoader && (
                        <CircularProgress
                          style={{
                            color: 'white',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      )
                    }
                    onClick={() => handleSubmit()}
                  >
                    <FormattedMessage id="Save & Add" />
                  </ReusableButton>
                </Stack>
              </div>
            </Row>
          </>
        </Row>
      </Col>
    </Row>
  );
};

export default ProviderEditSubCategory;
