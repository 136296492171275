import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import 'Pages/SuperAdmin/Dashboard/dashboard.scss';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import addIcon from 'assets/images/Dashboard/add.png';
import mail from 'assets/images/Dashboard/mail.png';
import call from 'assets/images/Dashboard/call.png';
import trash from 'assets/images/Dashboard/trash.png';
import ReusableTabs from 'components/common/Tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import ReusableButton from 'components/common/Button';
import {
  providerAdminAddSpecialists,
  providerAdminViewForm,
  superAdminCompanyProviderDetails,
  superAdminSalonProviderDetails,
  superAdminSpecialistProviderDetails,
} from 'routes/AppUrls';
import SwitchInput from 'components/common/Input/SwitchInput';
import { getUserRole } from 'utils/general';
import SelectInput from 'components/common/Input/SelectInput';
import { getSpecialistProviderCallAPI } from 'store/ProviderAdmin/getSpecialistProvider/action';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyBranchProviderCallAPI } from 'store/ProviderAdmin/getCompanyBranchProvider/action';
import moment from 'moment';
import { updateSpecialistStatusProviderCallAPI } from 'store/ProviderAdmin/statusUpdateSpecialistProvider/action';
import ToastNotification from 'components/common/Toast';

const ProviderAdminSpecialists = () => {
  const navigate = useNavigate();
  const userRole = getUserRole();
  const intl = useIntl();
  const [conf, setConf] = ToastNotification();
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const [tab, setTab] = useState(1);

  const profileData = useSelector((state) => state?.userMeReducer?.data?.data);
  const getBranchListData = useSelector(
    (state) => state?.getCompanyBranchProviderReducer?.data?.data
  );
  const getSpecialistListData = useSelector(
    (state) => state?.getCompanyBranchProviderReducer?.data?.data
  );
  const [specialistList, setSpecialistList] = useState(
    getSpecialistListData ?? []
  );
  const [branchId, setBranchId] = useState(
    getBranchListData?.length > 0 ? getBranchListData?.[0]?.id : ''
  );
  const [formValue, setFormValue] = useState({
    branch:
      getBranchListData?.length > 0
        ? getBranchListData?.[0]?.searched_business_name_en
        : '',
  });

  useEffect(() => {
    userRole === 'company-admin' ? getBranchList() : getSpecialistsList();
  }, []);

  const getBranchList = () => {
    dispatch(
      getCompanyBranchProviderCallAPI(
        {},
        {
          run: true,
          handleFunction: (res) => handleBranchSucess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleBranchSucess = (res) => {
    getSpecialistsList(res?.data?.data?.[0]?.id);
    setFormValue({
      ...formValue,
      ['branch']: res?.data?.data?.[0]?.searched_business_name_en,
    });
  };

  const getSpecialistsList = (branchSelectedId = '', type = 'onboarded') => {
    dispatch(
      getSpecialistProviderCallAPI(
        {},
        {
          id:
            userRole === 'company-admin'
              ? branchSelectedId
              : profileData?.provider_details?.id,
          type: type,
          run: true,
          handleFunction: (res) => handleSuccess(res),
          navigate: navigate,
        }
      )
    );
  };

  const handleSuccess = (res) => {
    setSpecialistList(res?.data?.data);
  };

  const handleSwitchChange = (data) => {
    let body = {
      employee_id: data?.id,
      employee_status:
        data?.invite_status === 'specialist-activated'
          ? 'specialist-deactivated'
          : 'specialist-activated',
    };
    dispatch(
      updateSpecialistStatusProviderCallAPI(body, {
        id:
          userRole === 'company-admin'
            ? branchId
            : profileData?.provider_details?.id,
        run: true,
        handleFunction: (res) => handleUpdateStatus(res),
        navigate: navigate,
      })
    );
  };

  const handleUpdateStatus = (res) => {
    setConf({
      msg: res?.data?.message,
      variant: 'success',
    });
    getSpecialistsList(branchId);
  };

  const tabData = [
    { label: intl.formatMessage({ id: 'Employees' }) },
    { label: intl.formatMessage({ id: 'Pending Onboarding' }) },
  ];

  const handleTabChange = (val) => {
    setTab(val);
    {
      val === 2
        ? getSpecialistsList(branchId, 'not_onboarded')
        : getSpecialistsList(branchId);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value?.searched_business_name_en });
    setBranchId(value?.id);
    {
      tab === 2
        ? getSpecialistsList(value?.id, 'not_onboarded')
        : getSpecialistsList(value?.id);
    }
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Specialists" />
        </h2>

        {userRole === 'company-admin' && (
          <Row className="m-0 p-0 ">
            <Col md={12} lg={8} xl={5} className="m-0 p-0 mt-3">
              <div className="flex items-center">
                <div className="w-40">
                  <p className="p-0 m-0 fs-16 fw-400 fc-A4AAB2 ff-Akkurat">
                    <FormattedMessage id="Filter by branch" />
                    {':'}
                  </p>
                </div>
                <SelectInput
                  marginLabelTop={'0'}
                  marginTop={'0'}
                  placeholder={intl.formatMessage({
                    id: 'Select a branch',
                  })}
                  name={'branch'}
                  value={formValue?.branch}
                  data={getBranchListData ?? []}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </Col>
          </Row>
        )}

        <Row className="m-0 p-0 flex justify-between">
          <Col md={12} lg={6} xl={4} className="m-0 p-0 mt-3">
            <ReusableTabs
              tabs={tabData}
              defaultTab={1}
              currentTab={(val) => handleTabChange(val)}
            />
          </Col>
          <Col
            md={12}
            lg={6}
            xl={8}
            className={`m-0 p-0 mt-3 text-start ${locale === 'en' ? 'text-lg-end' : 'text-lg-start'}`}
          >
            <ReusableButton
              fullWidth={false}
              variant="contained"
              color="#F5F5F5"
              fontSize="16px"
              fontWeight="400"
              padding="14px 16px"
              height="48px"
              bgColor="#292D32"
              fontFamily="Akkurat"
              onClick={() => navigate(providerAdminAddSpecialists(locale))}
            >
              <img
                src={addIcon}
                alt="icon"
                style={{ height: '24px', width: '24px' }}
                className="me-2"
              />
              <FormattedMessage id="Invite Specialist" />
            </ReusableButton>
          </Col>
        </Row>

        {tab === 1 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Specialist" />
                </p>
              </Col>
              {userRole === 'company-admin' && (
                <Col lg={2} md={6} sm={12}>
                  <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                    <FormattedMessage id="Branch" />
                  </p>
                </Col>
              )}
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Status" />
                </p>
              </Col>

              <Col
                lg={`${userRole === 'company-admin' ? 2 : 4}`}
                md={6}
                sm={12}
              >
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {specialistList?.length > 0 &&
                specialistList.map((key, index) => (
                  <Row className="m-0 p-0 flex items-center listAppointment">
                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {key?.name}
                      </p>
                    </Col>

                    {userRole === 'company-admin' && (
                      <Col lg={2} md={6} sm={12}>
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {'Branch Name'}
                        </p>
                        <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                          {'203A Spanish Tn Rd, Kingston'}
                        </p>
                      </Col>
                    )}

                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center "
                        >
                          <img
                            src={mail}
                            alt="icon"
                            style={{ height: '20px', width: '20px' }}
                          />
                          <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                            {key?.email}
                          </p>
                        </Stack>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center mt-2"
                        >
                          <img
                            src={call}
                            alt="icon"
                            style={{ height: '20px', width: '20px' }}
                          />
                          <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                            {key?.contact_number}
                          </p>
                        </Stack>
                      </p>
                    </Col>

                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                        {key?.created_at
                          ? moment(key?.created_at).format('DD MMM YYYY')
                          : ''}
                      </p>
                    </Col>

                    <Col lg={2} md={6} sm={12}>
                      <SwitchInput
                        label={true}
                        checked={
                          key?.invite_status === 'specialist-activated'
                            ? true
                            : false
                        }
                        onChange={() => handleSwitchChange(key)}
                        color="secondary"
                      />
                    </Col>

                    <Col
                      lg={`${userRole === 'company-admin' ? 2 : 4}`}
                      md={6}
                      sm={12}
                    >
                      <Stack
                        direction="horizontal"
                        gap={3}
                        className="items-center cursor-pointer"
                        onClick={() =>
                          index === 0
                            ? navigate(
                                superAdminSpecialistProviderDetails(locale),
                                { state: { data: key } }
                              )
                            : index === 1
                              ? navigate(
                                  superAdminSalonProviderDetails(locale),
                                  { state: { data: key } }
                                )
                              : navigate(
                                  superAdminCompanyProviderDetails(locale),
                                  { state: { data: key } }
                                )
                        }
                      >
                        <img
                          src={trash}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center "
                        >
                          <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                            <FormattedMessage id="View Details" />
                          </p>
                          <img
                            src={rightArrow}
                            alt="icon"
                            style={{ height: '20px', width: '20px' }}
                          />
                        </Stack>
                      </Stack>
                    </Col>
                  </Row>
                ))}
            </Row>
          </>
        )}

        {tab === 2 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Specialist" />
                </p>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Status" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {specialistList?.length > 0 &&
                specialistList.map((key, index) => (
                  <Row className="m-0 p-0 flex items-center listAppointment">
                    <Col lg={3} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {key?.name}
                      </p>
                    </Col>

                    <Col lg={3} md={6} sm={12}>
                      <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center "
                        >
                          <img
                            src={mail}
                            alt="icon"
                            style={{ height: '20px', width: '20px' }}
                          />
                          <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                            {key?.email}
                          </p>
                        </Stack>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center mt-2"
                        >
                          <img
                            src={call}
                            alt="icon"
                            style={{ height: '20px', width: '20px' }}
                          />
                          <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                            {key?.contact_number}
                          </p>
                        </Stack>
                      </p>
                    </Col>

                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                        {key?.created_at
                          ? moment(key?.created_at).format('DD MMM YYYY')
                          : ''}
                      </p>
                    </Col>

                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                        {key?.invite_status}
                      </p>
                    </Col>

                    <Col lg={2} md={6} sm={12}>
                      <p
                        className={`p-0 m-0 fs-14 fw-400 ${index === 0 ? 'fc-AF3C29 ff-Akkurat' : 'fc-1E1E1E ff-Akkurat_Bold cursor-pointer'}`}
                        onClick={() =>
                          index === 0
                            ? ''
                            : navigate(providerAdminViewForm(locale))
                        }
                      >
                        {index === 0 ? (
                          <FormattedMessage id="Cancel Invitation" />
                        ) : (
                          <FormattedMessage id="View Form" />
                        )}
                      </p>
                    </Col>
                  </Row>
                ))}
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default ProviderAdminSpecialists;
