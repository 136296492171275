import {
  REGISTER_INVITE_BUSINESS,
  REGISTER_INVITE_BUSINESS_FAILURE,
  REGISTER_INVITE_BUSINESS_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const registerInviteBusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_INVITE_BUSINESS:
      return { ...state, loading: true };
    case REGISTER_INVITE_BUSINESS_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case REGISTER_INVITE_BUSINESS_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default registerInviteBusinessReducer;
