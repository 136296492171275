import globalEn from 'utils/locale/common/en.json';
import categoryEn from 'utils/locale/common/category/en.json';
import loginEn from 'utils/locale/common/login/en.json';
import sidebarEn from 'utils/locale/common/sidebar/en.json';
import dashboardEn from 'utils/locale/common/dashboard/en.json';
import providerEn from 'utils/locale/common/provider/en.json';
import campaignEn from 'utils/locale/common/campaign/en.json';
import appointmentEn from 'utils/locale/common/appointment/en.json';
import customerEn from 'utils/locale/common/customer/en.json';

export default function TranslationsEn() {
  return {
    ...globalEn,
    ...categoryEn,
    ...loginEn,
    ...sidebarEn,
    ...dashboardEn,
    ...providerEn,
    ...campaignEn,
    ...appointmentEn,
    ...customerEn,
  };
}
