export const getLandingURL = (locale) => `/${locale}`;

////////////////////////SuperAdmin//////////////////////
export const login = (locale) => `/${locale}/login`;
export const superAdminDashboard = (locale) => `/${locale}/dashboard`;
export const superAdminRequest = (locale) => `/${locale}/request`;
export const superAdminProvider = (locale) => `/${locale}/provider`;
export const superAdminAddProvider = (locale) => `/${locale}/add-provider`;
export const superAdminAddBranch = (locale) => `/${locale}/add-branch`;
export const superAdminNewRequestProvider = (locale) =>
  `/${locale}/new-request-provider`;
export const superAdminOnHoldProvider = (locale) =>
  `/${locale}/on-hold-provider`;
export const superAdminSpecialistProviderDetails = (locale) =>
  `/${locale}/specialist-details`;
export const superAdminSalonProviderDetails = (locale) =>
  `/${locale}/salon-details`;
export const superAdminCompanyProviderDetails = (locale) =>
  `/${locale}/company-details`;
export const superAdminCustomer = (locale) => `/${locale}/customer`;
export const superAdminCustomerDetails = (locale) =>
  `/${locale}/customer-details`;
export const superAdminCustomerAppointmentDetails = (locale) =>
  `/${locale}/customer-appointment-details`;
export const superAdminCategory = (locale) => `/${locale}/category`;
export const superAdminAddCategory = (locale) => `/${locale}/add-category`;
export const superAdminAddSubCategory = (locale) =>
  `/${locale}/add-sub-category`;
export const superAdminAddService = (locale) => `/${locale}/add-service`;
export const superAdminEditService = (locale) => `/${locale}/edit-service`;
export const superAdminEditCategory = (locale) => `/${locale}/edit-category`;
export const superAdminEditSubCategory = (locale) =>
  `/${locale}/edit-sub-category`;
export const superAdminAppointments = (locale) => `/${locale}/appointments`;
export const superAdminAppointmentsDetails = (locale) =>
  `/${locale}/appointments-details`;
export const superAdminCampaigns = (locale) => `/${locale}/campaigns`;
export const superAdminAddCampaign = (locale) => `/${locale}/add-campaign`;

////////////////////////ProviderAdmin//////////////////////

export const getStarted = (locale) => `/${locale}/invite-send`;
export const createPassword = (locale) => `/${locale}/create-password`;
export const providerOnboarding = (locale) => `/${locale}/provider-onboarding`;
export const providerOnboardingAddBranch = (locale) =>
  `/${locale}/onboarding-add-branch`;
export const providerAdminDashboard = (locale) =>
  `/${locale}/provider-dashboard`;
export const providerAdminCustomer = (locale) => `/${locale}/provider-customer`;
export const providerAdminCustomerDetails = (locale) =>
  `/${locale}/provider-customer-details`;
export const providerAdminProfile = (locale) => `/${locale}/provider-profile`;
export const providerAdminSpecialists = (locale) =>
  `/${locale}/provider-specialists`;
export const providerAdminAddSpecialists = (locale) =>
  `/${locale}/provider-add-specialists`;
export const providerAdminViewForm = (locale) =>
  `/${locale}/provider-view-form`;
export const providerAdminBranches = (locale) => `/${locale}/provider-branches`;
export const providerAdminBranchesDetails = (locale) =>
  `/${locale}/provider-branches-details`;
export const providerAdminAddBranches = (locale) =>
  `/${locale}/provider-add-branches`;

export const providerAdminCategory = (locale) => `/${locale}/provider-category`;
export const providerAdminAddSubCategory = (locale) =>
  `/${locale}/provider-add-sub-category`;
export const providerAdminAddService = (locale) =>
  `/${locale}/provider-add-service`;
export const providerAdminEditService = (locale) =>
  `/${locale}/provider-edit-service`;
export const providerAdminEditSubCategory = (locale) =>
  `/${locale}/provider-edit-sub-category`;
export const providerAdminAppointments = (locale) =>
  `/${locale}/provider-appointment`;
export const providerAdminEditAppointments = (locale) =>
  `/${locale}/provider-edit-appointment`;
