import {
  COUNTRY_CITY_DROPDOWN,
  COUNTRY_CITY_DROPDOWN_FAILURE,
  COUNTRY_CITY_DROPDOWN_SUCCESS,
} from './action';

const initialState = {
  loading: false,
  data: [],
  error: '',
};

const countryCityDropdownReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTRY_CITY_DROPDOWN:
      return { ...state, loading: true };
    case COUNTRY_CITY_DROPDOWN_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: '' };
    case COUNTRY_CITY_DROPDOWN_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    default:
      return state;
  }
};

export default countryCityDropdownReducer;
