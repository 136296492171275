import { EmailValidation, PasswordValidation } from 'utils/helper';
import { FormattedMessage } from 'react-intl';

const CheckValidation = (value) => {
  const formError = {
    submit: true,
    email: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    password: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    confirmPassword: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    name: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    bussinessName: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    ownerName: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    specialistName: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    searchBussinessName: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    phoneNumber: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    categoryNameEn: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    categoryNameAr: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    subCategoryNameEn: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    subCategoryNameAr: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    serviceNameEn: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    serviceNameAr: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    image: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    country: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    city: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    bussinessEmail: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    bussinessPhoneNumber: {
      error: false,
      errorMessage: <FormattedMessage id="field is required!" />,
    },
  };

  const emptyKeys = [];

  for (let key in value) {
    emptyKeys.push(key);
  }

  for (let i = 0; i < emptyKeys.length; i++) {
    if (!value[emptyKeys[i]]) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
    }

    if (
      value[emptyKeys[i]] &&
      emptyKeys[i] === 'email' &&
      !EmailValidation(value[emptyKeys[i]])
    ) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
      formError[emptyKeys[i]].errorMessage = (
        <FormattedMessage id="Please Enter Valid Email!" />
      );
    }

    if (
      value[emptyKeys[i]] &&
      emptyKeys[i] === 'password' &&
      !PasswordValidation(value[emptyKeys[i]])
    ) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
      formError[emptyKeys[i]].errorMessage = 'Please Enter Valid Password';
    }

    if (
      value[emptyKeys[i]] &&
      emptyKeys[i] === 'confirmPassword' &&
      !(value?.[emptyKeys[0]] === value?.[emptyKeys[1]])
    ) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
      formError[emptyKeys[i]].errorMessage =
        'Password & Confirm Password Should be Same';
    }
  }
  return formError;
};

export default CheckValidation;
