import { getMethodWithToken } from 'services/apiServices';
import url from 'services/urls.json';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';

// Action Types
export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';

// Action Creators
export const fetchGetCategory = () => ({
  type: GET_CATEGORY,
});

export const fetchGetCategorySuccess = (data) => ({
  type: GET_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchGetCategoryFailure = (error) => ({
  type: GET_CATEGORY_FAILURE,
  payload: error,
});

// Thunk Function
export const getCategoryCallAPI = (data, external = {}) => {
  return async (dispatch) => {
    dispatch(fetchGetCategory(data, external));
    try {
      const userRole = getUserRole();
      const response = await getMethodWithToken(
        userRole === 'super-admin'
          ? url.getCategoryListSuperAdmin
          : url.getCategoryListProviderAdmin
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(fetchGetCategorySuccess(response.data));

        if (external && external.redirect === true) {
          external.redirectNavigate(external.path);
        } else if (external && external.run === true) {
          external.handleFunction(response);
        } else if (external && external.step) {
          external.handleStep(external.step);
        }
      } else {
        if (response.status === 401) {
          // ClearTokenRedirectLogin(external?.navigate);
        }
        if (external && external.toast) {
          external.handleToastError(response);
        }
      }
    } catch (error) {
      dispatch(fetchGetCategoryFailure(error.message));
    }
  };
};
